import React, { Component } from 'react';
import { apiUrl } from '../../config';
import { toast } from 'react-toastify';
import image from "../../images/pdf/h6.png"
class RiskAppreciationTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      np: 1,
      dph: 0.1,
      fe: 0.1,
      lo: 0.033,
      hrn: 0.00033,
      preview:false

    };
  }
  
componentDidMount(){

  const matchedItem = this.props.data.find(item => item.code === this.props.code);



  if(matchedItem){

 

    const { np, dph, fe, lo } = matchedItem;
    const npNumber = Number(np || 1);
    const dphNumber = Number(dph || 0.1);
    const feNumber = Number(fe || 0.1);
    const loNumber = Number(lo || 0.0033);
    const hrn = npNumber * dphNumber * feNumber * loNumber;
 



    if (typeof this.props.resp === 'function') {
     

     function conta(hrn){
      if (hrn < 5) {
        return ["ACEITÁVEL","#548235"]
      } else if (hrn < 25) {
        return ["BAIXO","#92d050"];
      } else if (hrn < 50) {
        return ["MODERADO","#ffff00"];
      } else if (hrn < 100) {
        return ["SIGNIFICATIVO","#ffc000"];
      } else if (hrn < 200) {
        return ["ALTO","#ed7d31"];
      } else if (hrn < 500) {
        return ["MUITO ALTO","#ff0000"];
      } else {
        return ["EXTREMO","#000000","#fff"];
      }
     }




     this.props.resp(conta(hrn)[0])
    }


    this.setState({
      np:npNumber,
      dph:dphNumber,
      fe:feNumber,
      lo:loNumber,
      hrn
    })


  
  }

  if(window.location.pathname.split("/")[1] == "preview"){
    this.setState({preview:true})
  }




}
  handleSelectChange = (event, key) => {
    
    this.setState(
      { [key]: parseFloat(event.target.value) },
      this.updateHRN
    );
   
    fetch(`${apiUrl}/FormHrn`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body:JSON.stringify({
        code:this.props.code,
        ref:window.location.pathname.split("/")[3],
        key,
        value:event.target.value
      })
    }).then(response => {

    if(response.status == "200"){
      response.json().then(data=>{
toast.success("Salvo!")
      })
    }

  })



  };

  updateHRN = () => {
    const { np, dph, fe, lo } = this.state;
    const hrn = np * dph * fe * lo;
    this.setState({ hrn });
  };

  getRiskClass = () => {
    const { hrn } = this.state;
    if (hrn < 5) {
      return ["ACEITÁVEL","#548235"]
    } else if (hrn < 25) {
      return ["BAIXO","#92d050"];
    } else if (hrn < 50) {
      return ["MODERADO","#ffff00"];
    } else if (hrn < 100) {
      return ["SIGNIFICATIVO","#ffc000"];
    } else if (hrn < 200) {
      return ["ALTO","#ed7d31"];
    } else if (hrn < 500) {
      return ["MUITO ALTO","#ff0000"];
    } else {
      return ["EXTREMO","#000000","#fff"];
    }
  };

  render() {
    const { hrn } = this.state;
    const riskClass = this.getRiskClass();
 
    return (
      <div>
        <table className="table-container">
          <thead code={this.props.code}>
            <tr>
              <th colSpan="6"  className="table-header">
          {this.props.title} {this.props.code}
              </th>
            </tr>
            <tr>
              <th className="table-header">NP</th>
              <th className="table-header">DPH</th>
              <th className="table-header">FE</th>
              <th className="table-header">LO</th>
              <th className="table-header">HRN</th>
              <th className="table-header">CLASSE DE RISCO</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="table-cell">
                <select
                  onChange={(e) => this.handleSelectChange(e, 'np')}
                  value={this.state.np}
                >
                  {[1, 2, 4, 8, 12].map((item) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </td>
              <td className="table-cell">
                <select
                  onChange={(e) => this.handleSelectChange(e, 'dph')}
                  value={this.state.dph}
                >
                  {[0.1, 0.5, 1, 2, 4, 8, 10, 12, 15].map((item) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </td>
              <td className="table-cell">
                <select
                  onChange={(e) => this.handleSelectChange(e, 'fe')}
                  value={this.state.fe}
                >
                  {[0.1, 0.5, 1, 1.5, 2.5, 4, 5].map((item) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </td>
              <td className="table-cell">
                <select
                  onChange={(e) => this.handleSelectChange(e, 'lo')}
                  value={this.state.lo}
                >
                  {[0.0033, 0.1, 1, 1.5, 2, 5, 10, 15].map((item) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </td>
              <td className="table-cell">{hrn.toFixed(5)}</td>
              <td className={`table-cell table-cell-risk}`} style={{color:`
              ${riskClass[2]!=undefined ?riskClass[2]:'#fff'}
              `,background:`${riskClass[1]}`}}>{riskClass[0]}</td>
            </tr>
          </tbody>
        </table>
        {this.state.preview == false && <>
          <div style={{display:'table',margin:'auto',marginTop:15}}>
        <img src={image}/>
        </div>
    
        </>}
       
      </div>

    );
  }
}

export default RiskAppreciationTable;