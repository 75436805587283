import React, { Component } from 'react';
import "./t.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faPlus, faSave } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { apiUrl } from '../../config';
import { json } from 'react-router-dom';
import { StaticDialog } from 'react-st-modal';
class TextView extends Component {
    constructor(props) {
        super(props);
        this.state = {
        data:[],
        text:undefined,
        ntext:'',
        textList:[],
        psq:'',
        dados:undefined
        }
    }
    componentDidMount(){

    const matchedItem = this.props.data.find(item => item.code === this.props.code);

    if(matchedItem){
    this.setState({dados:matchedItem})
    }else{
      this.setState({dados:undefined})
    }
    this.setState({data:this.props.data,text:matchedItem != undefined ? matchedItem.text : ''})
   
    }





handleChange(){

  var text = this.state.ntext;

    if (text.trim().length == 0) {
        //event.target.value = '';
      } else {
        const id = toast.loading("Salvando")
              
        fetch(`${apiUrl}/TextView`, {
            method: 'POST',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json',
            },
            body:JSON.stringify({
                ref:window.location.pathname.split("/")[3],
                code:this.props.code,
                text:text
            })
          }).then(response => {
  toast.update(id, { render:"Salvo!", type: "success", isLoading: false,autoClose: 3000});
              
response.json().then(data=>{
  this.setState({
    text,
    modal:false
  })

})
          })
        

      }
}



  render() {
  
    const { type,vz } = this.props;
    const listaFiltrada = this.state.textList.filter((item) =>
      item.text.toLowerCase().includes(this.state.psq.toLowerCase())
    );


if(window.location.pathname.split("/")[1] == "preview"){
  return(<div code={this.props.code}>
    {this.state.text == '' ? "" :  this.state.text}
    </div>)
}


if(vz == "v"){
  return(<div code={this.props.code}>
  {this.state.text == '' ? "Digite:" :  this.state.text}
  </div>)
}

    return (
      <>
<div class="area-txtview"  style={{marginTop:15}}>


<div style={{
  border:'solid 1px #ccc',
  padding:10,
  borderRadius:5,
  cursor:'pointer'
}} onClick={()=>{


  fetch(`${apiUrl}/text-list`, {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    }
  }).then(response => {
if(response.status == 200){
response.json().then(data=>{
  this.setState({textList:data})
})
}

  

})







  this.setState({modal:true,ntext:this.state.text})
}}>

{this.state.text ? <>{this.state.text}</>:<>Digite:</>}
</div>


{/*type == "input"?<>
<input class="form-control form-control-sm" value={this.state.text} onChange={(e)=>{
    this.setState({text:e.target.value})
}} onBlur={(event)=>{
    this.handleChange(event)
}} placeholder="Digite:"/>
</>:<>
<textarea class="form-control form-control-sm" onClick={()=>{
this.setState({modal:true})
}}  value={this.state.text} onChange={(e)=>{
    this.setState({text:e.target.value})
}} onBlur={(event)=>{
    this.handleChange(event)
}} placeholder="Digite:"></textarea>
</>*/}



</div>
    

<StaticDialog
        isOpen={this.state.modal}
        title="TEXTO:"
        onAfterClose={(result) => {
         
   this.setState({modal:false})
        }}
      >


<div style={{padding:10}}>
<textarea class="form-control form-control-sm"  onChange={(e)=>{

  
const value = e.target.value;
const lines = value.split("\n");

if (lines.length <= 3) {
  this.setState({ ntext: value });
} else {
  const limitedText = lines.slice(0, 3).join("\n");
  this.setState({ ntext: limitedText });
}




}}  value={this.state.ntext}  placeholder="Digite:"></textarea>
<input style={{
width:'100%',height:35,marginTop:10}} type='text' onChange={(e)=>{
  this.setState({psq:e.target.value})
}} placeholder='Pesquisar:'/>
<div class="area-ptxt">
{listaFiltrada.length > 0 ? <>
<ul>
{listaFiltrada.map((item)=><>
<li onClick={()=>{
  this.setState({ntext:item.text})
}}>{item.text}</li>
</>)}
</ul>

</> : <>Não há nada aqui!</>}
</div>
</div>

<hr/>

<div class="d-flex justify-content-end" style={{
margin:10
}}>
<button type="button" class="btn btn-primary" onClick={()=>{
   this.handleChange()
}} disabled={this.state.text != this.state.ntext ? false : true}>
  <FontAwesomeIcon icon={faSave} color='#fff'/> Salvar</button>
</div>


      </StaticDialog>



      </>
    );
  }
}

export default TextView;