import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement } from 'chart.js';

// Registrando os componentes necessários para o Chart.js
ChartJS.register(Title, Tooltip, Legend, ArcElement);

// Componente de gráfico de pizza
const GraficoPizza = ({ data }) => {
  // Contando as ocorrências de cada categoria
  const contagem = data.reduce((acc, categoria) => {
    acc[categoria] = (acc[categoria] || 0) + 1;
    return acc;
  }, {});

  // Criando os dados para o gráfico
  const tabelaData = [
    { descricao: "BAIXO", cor: "#93d15a" },
    { descricao: "ACEITÁVEL", cor: "#23af57" },
    { descricao: "ALTO", cor: "#df6b17" },
    { descricao: "SIGNIFICATIVO", cor: "#fec028" },
    { descricao: "EXTREMO", cor: "#000" },
    { descricao: "MODERADO", cor: "#ffff38" },
    { descricao: "MUITO ALTO", cor: "#fc0000" },
  ];

  const chartData = {
    labels: tabelaData.map(item => item.descricao),
    datasets: [
      {
        data: tabelaData.map(item => contagem[item.descricao] || 0),
        backgroundColor: tabelaData.map(item => item.cor),
        borderColor: '#fff',
        borderWidth: 1,
      }
    ]
  };

  return (
    <div>
      <h2>Gráfico de Risco</h2>
      <Pie data={chartData} />
    </div>
  );
};

export default GraficoPizza;
