import React from 'react';

// Componente para cada linha da tabela
const TableRow = ({ descricao, valor, percentual, cor,font }) => (
  <tr style={{ backgroundColor: cor,color:font!=undefined ? font : '#000' }}>
    <td>{descricao}</td>
    <td>{valor}</td>
    <td>{percentual}</td>
  </tr>
);

// Componente da tabela principal
const TabelaRisco = ({ data }) => {
  // Definindo os dados completos, com percentuais fixos para cada risco
  const tabelaData = [
    { descricao: "ACEITÁVEL", percentual: "14,28", cor: "green" },
    { descricao: "BAIXO", percentual: "14,28", cor: "lightgreen" },
    { descricao: "MODERADO", percentual: "14,28", cor: "yellow" },
    { descricao: "SIGNIFICATIVO", percentual: "14,28", cor: "gold" },
    { descricao: "ALTO", percentual: "28,60", cor: "orange" },
    { descricao: "MUITO ALTO", percentual: "0", cor: "red" },
    { descricao: "EXTREMO", percentual: "14,28", cor: "#000",font:"#fff" }
  ];

  // Função para contar a ocorrência de cada descrição em `data`
  const countOccurrences = (description) =>
    data.filter(item => item.toUpperCase() === description).length;

  // Ajustando os valores com base nas ocorrências em `data`
  const adjustedData = tabelaData.map(item => ({
    ...item,
    valor: countOccurrences(item.descricao.toUpperCase()) // Conta as ocorrências
  }));

  return (
    <table border="1">
      <thead>
        <tr>
          <th>Descrição</th>
          <th>Valor Nominal</th>
          <th>Percentil %</th>
        </tr>
      </thead>
      <tbody>
        {adjustedData.map((item, index) => (
          <TableRow 
            key={index} 
            descricao={item.descricao} 
            valor={item.valor} 
            percentual={item.percentual} 
            cor={item.cor} 
            font={item.font}
          />
        ))}
      </tbody>
    </table>
  );
};

export default TabelaRisco;
