import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash, faPen } from '@fortawesome/free-solid-svg-icons';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import { Confirm, StaticDialog } from 'react-st-modal';
import { apiUrl } from '../../config';


class Grouplist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      group_2: [],
      delete:undefined
    };
  }


componentDidMount(){
    
    const matchedItem = this.props.data.find(item => item.code === this.props.code);
  if(matchedItem){
    this.setState({group_2:matchedItem.itens})
  }else{
    this.setState({group_2:[]})
  }
}



handleChange(event,id,type,index){

    const file = event.target.files[0];
    if (file) {
      const fileType = file.type;
      if (!fileType.startsWith('image/')) {
        toast.warn("Por favor, selecione uma imagem válida!")
        event.target.value = null;
      } else {
       
  
  
        const formData = new FormData();
        formData.append('imagem', file);
        formData.append('id',id);
        formData.append('type',type)




        fetch(`${apiUrl}/img-gp3`, {
            method: 'POST',
            body: formData
           }).then(response => {
            if(response.status == 200){
toast.success("Sucesso!")
response.json().then(data=>{



    this.setState(prevState => {
        const novoGrupo2 = [...prevState.group_2];
        
        novoGrupo2[index][type] = data.img;
       
        return { group_2: novoGrupo2 };
      })


        

})

            }
        })


      }
    }
}



  render() {
    return (
      <div className="col-12 mt-3" style={{ display: 'block' }}>
        <div className="card">
          <div className="card-header d-flex justify-content-between">
            <h3>Imagens</h3>
            <button
              className="btn btn-primary"
              onClick={() => {
                const id = toast.loading("Criando grupo");
                fetch(`${apiUrl}/solicitante/${window.location.pathname.split("/")[3]}/create/group-image-3/`, {
                  method: 'POST',
                  credentials: 'include',
                  headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + Cookies.get("auth_token"),
                  },
                  body: JSON.stringify({ code:window.location.pathname.split("/")[3],ref:this.props.code  }),
                }).then(response => {
                  response.json().then(data => {
                    toast.update(id, { render: "Grupo criado com sucesso!", type: "success", isLoading: false, autoClose: 3000 });
                    this.setState(prevState => ({
                      group_2: [
                        ...prevState.group_2,
                        {id:data.id,ref:data.ref, itens: {} } // Adiciona um novo item com imgs como array vazio
                      ]
                    }));
                  });
                });
              }}
            >
              <FontAwesomeIcon icon={faPlus} color='#fff' /> Adicionar grupo
            </button>
          </div>

          <div className="card-body">
      
      



            {this.state.group_2.length > 0 ? <>
        <table class="table ntbl table-bordered">
   <thead>
      <tr>
         <th scope="col">Grupo</th>
         <th scope="col">Item</th>
         <th scope="col">Opção</th>
      </tr>
   </thead>
   <tbody>

     {this.state.group_2.map((item,index)=><>
        <tr>
       <td>{index + 1}</td>
        <td>
        

            <div class="area-8" >




            {item.img1 != null ? <>
            
                <div class="preview">
                <div class="edit">
                <input type="file" onChange={(event)=>{
this.handleChange(event,item.id,'img1',index)
}}/>
    <FontAwesomeIcon icon={faPen} color='#fff'/>
                </div>
                <img  src={`${apiUrl}/img/${item.img1}`} />
                </div>
            
            
            
            </> : <>
                <div class="add">
<input type="file" onChange={(event)=>{
this.handleChange(event,item.id,'img1',index)
}}/>
           <FontAwesomeIcon icon={faPlus} color='#282828'/>     </div>
            
            
            </>}


            {item.img2 != null ? <>
            
            <div class="preview">
            <div class="edit">
            <input type="file" onChange={(event)=>{
this.handleChange(event,item.id,'img2',index)
}}/>
<FontAwesomeIcon icon={faPen} color='#fff'/>
            </div>
            <img  src={`${apiUrl}/img/${item.img2}`} />
            </div>
        
        
        
        </> : <>
            <div class="add">
<input type="file" onChange={(event)=>{
this.handleChange(event,item.id,'img2',index)
}}/>
       <FontAwesomeIcon icon={faPlus} color='#282828'/>     </div>
        
        
        </>}





        {item.img3 != null ? <>
            
            <div class="preview">
            <div class="edit">
            <input type="file" onChange={(event)=>{
this.handleChange(event,item.id,'img3',index)
}}/>
<FontAwesomeIcon icon={faPen} color='#fff'/>
            </div>
            <img  src={`${apiUrl}/img/${item.img3}`} />
            </div>
        
        
        
        </> : <>
            <div class="add">
<input type="file" onChange={(event)=>{
this.handleChange(event,item.id,'img3',index)
}}/>
       <FontAwesomeIcon icon={faPlus} color='#282828'/>     </div>
        
        
        </>}

        {item.img4 != null ? <>
            
            <div class="preview">
            <div class="edit">
            <input type="file" onChange={(event)=>{
this.handleChange(event,item.id,'img4',index)
}}/>
<FontAwesomeIcon icon={faPen} color='#fff'/>
            </div>
            <img  src={`${apiUrl}/img/${item.img4}`} />
            </div>
        
        
        
        </> : <>
            <div class="add">
<input type="file" onChange={(event)=>{
this.handleChange(event,item.id,'img4',index)
}}/>
       <FontAwesomeIcon icon={faPlus} color='#282828'/>     </div>
        
        
        </>}







 



            </div>
            
     
            
            
            
            
        </td>
        <td>
            <button className='btn btn-danger' onClick={(e)=>{
this.setState({delete:item})
            }}>
                <FontAwesomeIcon icon={faTrash} />
            </button>
        </td>
     </tr>
     </>)}
   
     
   </tbody>
</table>

    </> : <>
    Vázio!
    </>}






          </div>
        </div>

        <StaticDialog
        isOpen={this.state.delete != undefined}
        title="Atenção"
        onAfterClose={(result) => {
         
   this.setState({delete:undefined})
        }}
      >


<div style={{padding:10}}>
Uma vez deletado, não será possível recuperar
</div>

<div className='b-modal'>
<button className='btn btn-danger' onClick={(e)=>{
var del = (this.state.delete)

e.target.classList.add('load')



fetch(`${apiUrl}/grouplist/delete/`, {
  method: 'DELETE',
  credentials: 'include',
  headers: {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer '+Cookies.get("auth_token"),
  },
  body:JSON.stringify({id:del.id})
}).then(response => {

  if(response.status == 200){
    e.target.classList.remove('load')
    const filteredItems = this.state.group_2.filter(item => item.id !== del.id);
    this.setState({ group_2: filteredItems,delete:undefined });
    toast.success('Grupo removido')
  }

})






}}>
  <FontAwesomeIcon icon={faTrash}/> Remover
</button>
</div>


      </StaticDialog>
      </div>

      
    );
  }
}

export default Grouplist;
