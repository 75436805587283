import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave,faDownload, faPen, faArrowLeft, faAngleLeft,} from '@fortawesome/free-solid-svg-icons';
import { apiUrl } from '../../config';
import "./t.css"
import f1 from "../../images/pdf/f1.png"
import f2 from "../../images/pdf/f2.png"
import f3 from "../../images/pdf/f3.png"
import f4 from "../../images/pdf/f4.png"
import f5 from "../../images/pdf/f5.png"
import f6 from "../../images/pdf/f6.png"
import f7 from "../../images/pdf/f7.png"
import f8 from "../../images/pdf/f8.png"
import f10 from "../../images/pdf/f10.png"
import f11 from "../../images/pdf/f11.png"
import f12 from "../../images/pdf/f11.png"
import g1 from "../../images/pdf/g1.png"
import g2 from "../../images/pdf/g2.png"
import g3 from "../../images/pdf/g3.png"
import g4 from "../../images/pdf/g4.png"
import g5 from "../../images/pdf/g5.png"
import g6 from "../../images/pdf/g6.png"
import g7 from "../../images/pdf/g7.png"
import g8 from "../../images/pdf/g8.png"
import g9 from "../../images/pdf/g9.png"
import h1 from "../../images/pdf/h1.png"
import h2 from "../../images/pdf/h2.png"
import h3 from "../../images/pdf/h3.png"
import h4 from "../../images/pdf/h4.png"

import TreeNode from '../../Comps/Tree';
import RiskAppreciationTable from '../../Comps/RiskAppreciationTable';
import ImageView from '../../Comps/ImageView';
import TextView from '../../Comps/TextView';
class Teste extends Component {
    constructor(props) {
      super(props);
      this.state = {
          Loader:true,
          data:[],
          form:[],
          letra:'',
          callCount: 0,
          tabela:[
            ["B", "1", "2", "3", "4"],
            ["•", "○", "●", "●", "●"],
            ["•", "•", "○", "●", "●"],
            ["", "•", "•", "○", "●"],
            ["", "•", "•", "○", "●"],
            ["", "", "", "•", "○"]  
          ],
          selectedItems:[""],
          ctg:''
        };
    }

   formatDate(inputDate) {
      // Cria um objeto Date a partir da string de entrada
      let date = new Date(inputDate);
  
      // Extrai o dia, o mês e o ano
      let day = String(date.getDate()).padStart(2, '0'); // Garante que o dia tenha dois dígitos
      let month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() retorna o mês de 0 a 11
      let year = date.getFullYear();
  
      // Retorna a data formatada como "dd/mm/yyyy"
      return `${day}/${month}/${year}`;
  }





    componentDidMount() {
        document.title = "Editar norma";
        fetch(`${apiUrl}/gerar-documento/${window.location.pathname.split("/")[2]}`, {
            method: 'GET',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer '+Cookies.get("auth_token"),
            }
          }).then(response => {
            response.json().then(data=>{
           this.setState({Loader:false,
           data,
           TextView:data.TextView,
           FormHrn:data.FormHrn,
           ImageView:data.ImageView,
           groupi:data.groupi
          })

var list = data.diagram.join()

var letra = undefined;
switch(list){
case 's1-1,f1-2,p1-3':
  letra = "a";
  this.setState({selectedItems:["0-1"],ctg:'1'})
  break;
case 's1-1,f1-2,p2-4':
  this.setState({selectedItems:["0-1"],ctg:'1'})
  letra = "b";
  break;
case 's1-1,f2-5,p1-6':
  letra = "b";
  this.setState({selectedItems:["0-1"],ctg:'1'})
  break;
case 's1-1,f2-5,p2-7':
  letra = "c";
  this.setState({selectedItems:["0-1"],ctg:'1'})
  break;
case 's2-8,f1-9,p1-10':
  letra = "c"
  this.setState({selectedItems:["1-2"],ctg:'2'})
  break;
case 's2-8,f1-9,p2-11':
  this.setState({selectedItems:["2-3"],ctg:'3'})
  letra = "d";
  break;
case 's2-8,f2-12,p1-13':
  letra = "d";
  this.setState({selectedItems:["3-3"],ctg:'3'})
  break;
case 's2-8,f2-12,p2-14':
  letra = "e"
  this.setState({selectedItems:["4-4"],ctg:'4'})
  break;
  default:
    letra = "Indefinido!"
}

this.setState({letra})

            })





          })

    }
    handleItemClick = (item) => {
      this.setState({ selectedItem: item });
    };
    
    TextView(code){
      var r = this.state.TextView.find(item => item.code === code)
      return(<>
      {r!= undefined && r.text}
      </>)
      
     
    }
      
    ViewImage(code){




      var r = this.state.ImageView.find(item => item.code === code)
 
      return(r!= undefined && r.img)
      
     
    }



render(){
  const { selectedItems } = this.state;

  var b = this.state.data.body;
    if(this.state.Loader){
        return(<>Carregando..</>)
    }

    return (
        <div className='printer'>

    <section id="p1" style={{display:'block'}}>
             <div class='t1'>
                 <div>A.R</div>
                 <div>APRECIAÇÃO DE RISCOS</div>
           </div>
           <div class='t2'>
             <div>EXECUTANTE:</div>
             <div>{this.state.data.executante.razao_social}</div>
           </div>
         
           <div class='t3'>
         <div class='img'>
         <img src={`http://api.rifa.com/img/${this.state.data.executante.logo}`} />
         </div>
           </div>
         
         
           <div class='t4'>
            <div>CONTRATANTE:</div>
            <div>{this.state.data.contratante.razao_social}</div>
           </div>
         
           <div class='t3'>
         <div class='img'>
         <img src={`http://api.rifa.com/img/${this.state.data.contratante.logo}`} />
         </div>
           </div>
          
          <div class='t6'>
         <div>MÁQUINA / EQUIPAMENTO: {b.maquina.toUpperCase()}</div>
         <div>ART VINCULADA: Nº {b.art_vinculada}</div>
          </div>
         
         <div class='t7'>
         <div>{b.estado}</div>
         <div>{this.formatDate(b.data)}</div>
         </div>
         
         <div class='t8'>
         <div class="qr">
          <img src={`http://api.rifa.com/img/${b.qr_code}`}/>
         </div>
         </div>
         
         <div class='t9'>
             <div>As informações deste documento são confidenciais. Informações fornecidas e destinadas ao uso interno da empresa executante/contratante não sendo permitida a cópia, reprodução total ou parcial de qualquer tipo de informação deste documento sem a expressa autorização das partes envolvidas sob as penas da lei.</div>
         </div>
         
         <div class='t10'>
             <div>{b.site}</div>
         </div>
    </section>



     {false && <>
      <section id="p2"  >
         <div class="ctn">
         <div class="area-mtp">
        
     <div class="area-a">
     <div>Data: {this.formatDate(b.data)}</div>
     <div>Rev: V1.2</div>
     <div>Número: AR 01</div>
     <div>Página 2 de 59</div>
     </div>
     
     <div class="area-b">
     <div>A.R. APRECIAÇÃO DE RISCOS</div>
     <div>{b.maquina.toUpperCase()}</div>
     </div>
     
     <div class="area-c">
     c
     </div>
     
     
         </div>
     
         <div className='smr'>
     <span>Sumário</span>
     <div className='sub'></div>
     <div className='fn'></div>
     </div>
     <ul className="topic-list">
             {[
       "1- INTRODUÇÃO 3",
       "2- NORMAS REFERENCIADAS 4",
       "3- CARACTERIZAÇÃO DA EMPRESA SOLICITANTE 5",
       "4- CARACTERIZAÇÃO DA EMPRESA EXECUTANTE 5",
       "5- RESPONSÁVEIS TÉCNICOS 6",
       "6- ESCOPO 7",
       "7- MÉTODO DE AVALIAÇÃO PARA ESTIMATIVA DE RISCO CONFORME METODOLOGIA HAZARD RATING NUMBER (HRN) 8",
       "8- DETERMINAÇÃO DA CATEGORIA 11",
       "9- DOCUMENTAÇÃO DO EQUIPAMENTO: 21",
       "10- VISÃO GERAL DA MÁQUINA 22",
       "11- DEFINIÇÃO DA CATEGORIA DE SEGURANÇA 26",
       "12- AVALIAÇÃO – APRECIAÇÃO DE RISCOS 28",
       "12.1 – Arranjo físico das instalações – Geral 28",
       "12.2 Instalações e dispositivos elétricos – Geral 28",
       "12.3 Dispositivo de partida, acionamento e parada – Geral 37",
       "12.4 Sistemas de Segurança 38",
       "12.5 Dispositivo de parada de emergência – Geral 51",
       "12.6 Manutenção, inspeção, preparação, ajuste, reparo e limpeza. 51",
       "12.7 Sinalização, manuais, procedimentos e capacitação 53",
       "13- VISÃO GERAL DE CONFORMIDADE 55",
       "14- CONSIDERAÇÕES FINAIS 56",
       "15- CORPO TÉCNICO RESPONSÁVEL 57"
     ].map((topic, index) => {
               const [leftPart, rightPart] = topic.split(/\s(\d+)$/);
               return (
                 <li key={index} className="topic-item">
                   <span className="topic-left">{leftPart}</span>
                   <span className="dots"></span>
                   <span className="topic-right">{rightPart}</span>
                 </li>
               );
             })}
           </ul>
           <br/>
           <div className='site'>{b.site}</div>
         </div>
         
      </section>    
      <section id="p2" className='p3'  >
         <div class="ctn">
         <div class="area-mtp">
        
     <div class="area-a">
     <div>Data: {this.formatDate(b.data)}</div>
     <div>Rev: V1.2</div>
     <div>Número: AR 01</div>
     <div>Página 3 de 59</div>
     </div>
     
     <div class="area-b">
     <div>A.R. APRECIAÇÃO DE RISCOS</div>
     <div>{b.maquina.toUpperCase()}</div>
     </div>
     
     <div class="area-c">
     c
     </div>
     
     
         </div>
     
        <div className='txt-p3'>
         <span>1- INTRODUÇÃO</span>
         <div>
         Tendo em vista a adequação de segurança para máquinas e equipamentos conforme NR-12 port.       916 de 30 de julho de 2019 o presente documento denominado “A.R”. –  Apreciação de Riscos tem como objetivo realizar a análise técnica do equipamento: <span className='ttl'>{b.maquina.toUpperCase()}</span> segundo normas técnicas oficiais vigentes.
     
         </div>
        </div>
     
           <br/>
           <div className='site-p2'>{b.site}</div>
         </div>
         
      </section>
      <section id="p2" className='ac4' >
         <div class="ctn">
         <div class="area-mtp">
        
     <div class="area-a">
     <div>Data: {this.formatDate(b.data)}</div>
     <div>Rev: V1.2</div>
     <div>Número: AR 01</div>
     <div>Página 4 de 59</div>
     </div>
     
     <div class="area-b">
     <div>A.R. APRECIAÇÃO DE RISCOS</div>
     <div>{b.maquina.toUpperCase()}</div>
     </div>
     
     <div class="area-c">
     c
     </div>
     
     
         </div>
     

  <div className='ttl'>2- NORMAS REFERENCIADAS</div>
  <div class="area-normas">
  {this.state.data.normas.length > 0 ? <>
    {this.state.data.normas.map((item)=><div className='item'>
    <strong>{item.titulo}</strong> - <label>{item.cont}</label>
    </div>)}
  </> : <>
  Não há dados!
  </>}
  </div>   
           <br/>
           <div className='site-2'>{b.site}</div>
         </div>
         
      </section>
      <section id="p2" className="ac4 pg5" style={{marginTop:50}}>
         <div class="ctn">
         <div class="area-mtp">
        
     <div class="area-a">
     <div>Data: {this.formatDate(b.data)}</div>
     <div>Rev: V1.2</div>
     <div>Número: AR 01</div>
     <div>Página 4 de 59</div>
     </div>
     
     <div class="area-b">
     <div>A.R. APRECIAÇÃO DE RISCOS</div>
     <div>{b.maquina.toUpperCase()}</div>
     </div>
     
     <div class="area-c">
     c
     </div>
     
     
         </div>
     
         <div className='ttl'>3- CARACTERIZAÇÃO DA EMPRESA SOLICITANTE</div>
         <div className='img-slc'>
         <img src={`http://api.rifa.com/img/${this.state.data.contratante.logo}`} />
         </div>
         <div className='dados'>

         <div className='item'>
          <strong>Razão social</strong><label>{this.state.data.contratante.razao_social}</label>
         </div>

         <div className='item'>
          <strong>Cnpj</strong><label>{this.state.data.contratante.cnpj}</label>
         </div>

         <div className='item'>
          <strong>Endereço</strong><label>{this.state.data.contratante.endereco}</label>
         </div>


         <div className='item'>
          <strong>Estado</strong><label>{this.state.data.contratante.estado}</label>
         </div>



         <div className='item'>
          <strong>Cep</strong><label>{this.state.data.contratante.cep}</label>
         </div>
         </div>

          

         <div className='ttl' style={{marginTop:30}}>4- CARACTERIZAÇÃO DA EMPRESA EXECUTANTE</div>
         <div className='img-slc'>
         <img src={`http://api.rifa.com/img/${this.state.data.executante.logo}`} />
         </div>
         <div className='dados'>

         <div className='item'>
          <strong>Razão social</strong><label>{this.state.data.executante.razao_social}</label>
         </div>

         <div className='item'>
          <strong>Cnpj</strong><label>{this.state.data.executante.cnpj}</label>
         </div>

         <div className='item'>
          <strong>Endereço</strong><label>{this.state.data.executante.endereco}</label>
         </div>


         <div className='item'>
          <strong>Estado</strong><label>{this.state.data.executante.estado}</label>
         </div>



         <div className='item'>
          <strong>Cep</strong><label>{this.state.data.executante.cep}</label>
         </div>

         <div className='item'>
          <strong>Telefone</strong><label>{this.state.data.executante.telefone}</label>
         </div>

         <div className='item'>
          <strong>Email</strong><label>{this.state.data.executante.email}</label>
         </div>
         </div>
           <div className='site-3'>{b.site}</div>
         </div>
         
      </section>


      
     </>}
      
     <section id="p2" className="ac4 pg5" style={{marginTop:50,display:'block'}}>
         <div class="ctn">
         <div class="area-mtp">
        
     <div class="area-a">
     <div>Data: {this.formatDate(b.data)}</div>
     <div>Rev: V1.2</div>
     <div>Número: AR 01</div>
     <div>Página 4 de 59</div>
     </div>
     
     <div class="area-b">
     <div>A.R. APRECIAÇÃO DE RISCOS</div>
     <div>{b.maquina.toUpperCase()}</div>
     </div>
     
     <div class="area-c">
     c
     </div>
     
     
         </div>
     
       
     
          

         <div className='ttl' style={{marginTop:30}}>5- RESPONSÁVEIS TÉCNICOS</div>
        
         <div className='dados'>
{this.state.data.tecs.map((item)=><div className='item'>
  <strong>Responsável Técnico:</strong><label>{item.nome} - {item.func}</label>
</div>)}
        
    
<div style={{fontSize:14,marginTop:30}}>A responsabilidade técnica é exclusivamente para a elaboração da apreciação de riscos mediante ao recolhimento de ART (anotação de responsabilidade técnica).
</div>
         </div>
        
           <div className='site-4'>{b.site}</div>
         </div>
         
      </section>


      <section id="p2" className="ac4 pg5" style={{marginTop:50,display:'block'}}>
         <div class="ctn">
         <div class="area-mtp">
        
     <div class="area-a">
     <div>Data: {this.formatDate(b.data)}</div>
     <div>Rev: V1.2</div>
     <div>Número: AR 01</div>
     <div>Página 7 de 59</div>
     </div>
     
     <div class="area-b">
     <div>A.R. APRECIAÇÃO DE RISCOS</div>
     <div>{b.maquina.toUpperCase()}</div>
     </div>
     
     <div class="area-c">
     c
     </div>
     
     
         </div>
     
       
     
          

         <div className='ttl' style={{marginTop:30}}>6- ESCOPO</div>
     
         <div className='dados'>

         O objetivo deste documento é a elaboração da apreciação de riscos de máquinas e equipamentos expondo todos os perigos existentes para que possam ser regularizados posteriormente, ou seja, a segurança absoluta não é um estado completamente acessível e, portanto, o objetivo é atingir o mais alto nível de segurança possível, levando-se em conta o estado da técnica.
         A utilização dos métodos elencados nas páginas a seguir visa ações de melhoria a fim de mitigar ou eliminar os riscos existentes na operação baseando-se nas normas oficiais vigentes <strong>NR12 (NR-12 – SEGURANÇA NO TRABALHO EM MÁQUINAS E EQUIPAMENTOS), ABNT NBR ISO 12100 – Segurança de máquinas – Princípios gerais de projeto – Apreciação e redução de riscos.</strong>
        
         </div>
        
           <div className='site-4'>{b.site}</div>
         </div>
         
      </section>




      <section id="p2" className="ac4 pg5" style={{marginTop:50,display:'block'}}>
         <div class="ctn">
         <div class="area-mtp">
        
     <div class="area-a">
     <div>Data: {this.formatDate(b.data)}</div>
     <div>Rev: V1.2</div>
     <div>Número: AR 01</div>
     <div>Página 8 de 59</div>
     </div>
     
     <div class="area-b">
     <div>A.R. APRECIAÇÃO DE RISCOS</div>
     <div>{b.maquina.toUpperCase()}</div>
     </div>
     
     <div class="area-c">
     c
     </div>
     
     
         </div>
     
       
     
          

         <div className='ttl' style={{marginTop:30}}>7- MÉTODO DE AVALIAÇÃO PARA ESTIMATIVA DE RISCO CONFORME METODOLOGIA HAZARD RATING NUMBER (HRN)</div>
        
         <div className='dados'>

<div>Foi utilizado como ferramenta para quantificação e graduação do risco o método chamado Hazard Rating Number (HRN), ou seja, Número de Avaliação de Perigos. Este método é usado para classificar um risco entre Aceitável a Extremo, dando ao risco uma nota baseada em diversos fatores e parâmetros.</div>
<div style={{marginTop:10}}>Os parâmetros utilizados por este método são:</div>     
<ul>
          <li>A probabilidade de ocorrência (LO) de estar em contato com o risco</li>
          <li>A frequência de exposição ao risco (FE) </li>
          <li>O grau de severidade do dano (DPH) </li>
          <li>O número de pessoas exposta ao risco (NP) </li>
</ul>
<div>Para cada item mencionado acima é estabelecido um número que representa a variável de cálculo usada para encontrar o HRN do risco ou item avaliado. A formula aplicada para encontrar o nível de risco quantificado é a seguinte:</div>
<div style={{textAlign:'center',marginTop:10}}><strong>HRN = NP x DPH x FE x LO</strong></div>
<div style={{marginTop:10}}>Os parâmetros mencionados assim como as variáveis que cada um representa estão mencionados na tabela que se segue: </div>
</div>
        


           <div className='site-4'>{b.site}</div>
         </div>
         
      </section>


      <section id="p2" className="ac4 pg5" style={{marginTop:50,display:'block'}}>
         <div class="ctn">
         <div class="area-mtp">
        
     <div class="area-a">
     <div>Data: {this.formatDate(b.data)}</div>
     <div>Rev: V1.2</div>
     <div>Número: AR 01</div>
     <div>Página 9 de 59</div>
     </div>
     
     <div class="area-b">
     <div>A.R. APRECIAÇÃO DE RISCOS</div>
     <div>{b.maquina.toUpperCase()}</div>
     </div>
     
     <div class="area-c">
     c
     </div>
     
     
         </div>
     
       
     
          

         
         <div style={{display:'table',margin:'auto'}}>
<img src={f1} style={{width:'100%',height:'100%'}}/>
<div style={{marginTop:30}}>
  <strong>RESULTADO DO CÁLCULO:</strong>
</div>
         </div>
        


           <div className=''>{b.site}</div>
         </div>
         
      </section>


      <section id="p2" className="ac4 pg5" style={{marginTop:50,display:'block'}}>
         <div class="ctn">
         <div class="area-mtp">
        
     <div class="area-a">
     <div>Data: {this.formatDate(b.data)}</div>
     <div>Rev: V1.2</div>
     <div>Número: AR 01</div>
     <div>Página 10 de 59</div>
     </div>
     
     <div class="area-b">
     <div>A.R. APRECIAÇÃO DE RISCOS</div>
     <div>{b.maquina.toUpperCase()}</div>
     </div>
     
     <div class="area-c">
     c
     </div>
     
     
         </div>
     
       
     
          

         
         <div style={{display:'table',margin:'auto'}}>
<img src={f2} style={{width:'100%',height:'100%'}}/>

         </div>
        


           <div className=''>{b.site}</div>
         </div>
         
      </section>


      <section id="p2" className="ac4 pg5" style={{marginTop:50,display:'block'}}>
         <div class="ctn">
         <div class="area-mtp">
        
     <div class="area-a">
     <div>Data: {this.formatDate(b.data)}</div>
     <div>Rev: V1.2</div>
     <div>Número: AR 01</div>
     <div>Página 11 de 59</div>
     </div>
     
     <div class="area-b">
     <div>A.R. APRECIAÇÃO DE RISCOS</div>
     <div>{b.maquina.toUpperCase()}</div>
     </div>
     
     <div class="area-c">
     c
     </div>
     
     
         </div>
     
       
 <div className='ttl' style={{marginTop:30}}>
8- DETERMINAÇÃO DA CATEGORIA</div>
        
         <div className='dados'>

<div>Quando a máquina possuir a necessidade de uma solução de segurança com a utilização de sistemas eletrônicos (interfaces, sensores e atuadores) deve se atentar em qual categoria de segurança os riscos serão controlados. Para tais informações deve se levar em consideração os parâmetros de
  <strong>Severidade do ferimento, Frequência e/ou tempo de exposição ao perigo e Possibilidade de evitar o perigo (citados nas normas) ISSO 12100: 2013, ISO/TR 14.121-2: 2012 e NBR 14153: 2022, estas normas são utilizadas como base para a elaboração desta apreciação de riscos.</strong>
<div style={{marginTop:10,marginBottom:10,textAlign:'center'}}>
  <strong>NBR 14153: 2022</strong>
</div>
<div style={{display:'table',margin:'auto'}}>
<img src={f3} style={{with:600,height:'100%'}}/>
</div>

<div><strong>Os critérios de seleção:</strong></div>

<div style={{marginTop:10}}><strong>S – Severidade do ferimento </strong></div>
<ul>
          <li>S1: Ferimento leve (normalmente reversível)</li>
          <li>S2: Ferimento sério (normalmente irreversível) incluindo morte </li>
  
</ul>


<div style={{marginTop:10}}><strong>F – Frequência e/ou tempo de exposição ao perigo
</strong></div>
<ul>
          <li>F1: Raro a relativamente frequente e/ou baixo tempo de exposição</li>
          <li>F2: Frequente a contínuo e/ou tempo de exposição longo </li>
  
</ul>

<div style={{marginTop:10}}><strong>P – Possibilidade de evitar o perigo
</strong></div>
<ul>
          <li>P1: Possível sob condições específicas</li>
          <li>P2: Quase nunca possível. </li>
  
</ul>



</div>
</div>
        


           <div>{b.site}</div>
         </div>
         
      </section>






      <section id="p2" className="ac4 pg5" style={{marginTop:50,display:'block'}}>
         <div class="ctn">
         <div class="area-mtp">
        
     <div class="area-a">
     <div>Data: {this.formatDate(b.data)}</div>
     <div>Rev: V1.2</div>
     <div>Número: AR 01</div>
     <div>Página 12 de 59</div>
     </div>
     
     <div class="area-b">
     <div>A.R. APRECIAÇÃO DE RISCOS</div>
     <div>{b.maquina.toUpperCase()}</div>
     </div>
     
     <div class="area-c">
     c
     </div>
     
     
         </div>
     
       
 <div className='ttl' style={{marginTop:30}}>
 As categorias de Segurança: <br/><br/>Categoria B</div>
        
<div className='dados'>
<div>As partes relacionadas à segurança devem ser projetadas de tal forma que resistam:</div>

<div style={{marginTop:10}}>Fadiga operacional prevista, como, por exemplo, a confiabilidade com respeito à capacidade e frequência de comutação;</div>

<div style={{marginTop:10}}> Influência do material processado ou utilizado no processo, como, por exemplo, detergentes em máquinas de lavar;
</div>
<div style={{marginTop:10}}>Outras influências externas relevantes, como, por exemplo, vibrações mecânicas, campos externos, distúrbios ou interrupção do fornecimento de energia.</div>
<div style={{display:'table',margin:'auto',marginTop:50}}>
  <img src={f4} style={{width:'100%',height:'100px'}}/>
</div>
</div>
        


           <div>{b.site}</div>
         </div>
         
      </section>



      <section id="p2" className="ac4 pg5" style={{marginTop:50,display:'block'}}>
         <div class="ctn">
         <div class="area-mtp">
        
     <div class="area-a">
     <div>Data: {this.formatDate(b.data)}</div>
     <div>Rev: V1.2</div>
     <div>Número: AR 01</div>
     <div>Página 13 de 59</div>
     </div>
     
     <div class="area-b">
     <div>A.R. APRECIAÇÃO DE RISCOS</div>
     <div>{b.maquina.toUpperCase()}</div>
     </div>
     
     <div class="area-c">
     c
     </div>
     
     
         </div>
     
       

        
<div className='dados'>
  <div>Construído e verificado utilizando-se princípios que demonstrem sua adequação e confiabilidade para aplicações relacionadas à segurança.</div>
<div style={{display:'table',margin:'auto',marginTop:10}}>
  <img src={f8} style={{width:'100%',height:200}}/>
</div>
<div>
  <strong>
  Categoria 2
  </strong>
</div>
<div>
As partes de sistemas de comando relacionadas à segurança, de categoria 2, devem ser projetadas de tal forma que sejam verificadas em intervalos adequados pelo sistema de comando da máquina.
</div>
<div>
As verificações das funções de segurança devem ser efetuadas:
</div>
<div>
- Na partida da máquina e antes do início de qualquer situação de perigo, e
</div>
<div>
- Periodicamente durante a operação, se a avaliação do risco e o tipo de operação mostrarem que isso é necessário.

</div>

<div style={{display:'table',margin:'auto',marginTop:10}}>
  <img src={f10} style={{width:'100%',height:200}}/>
</div>
<div style={{marginBottom:20}}>
  <strong>
  Categoria 3
  </strong>
</div>
<div>
Partes relacionadas à segurança de sistemas de comando de categoria 3 devem ser projetadas de tal forma que um defeito isolado, em qualquer dessas partes, não leve à perda das funções de segurança. Defeitos de modos comuns devem ser considerados, quando a probabilidade da
</div>
</div>
        


           <div>{b.site}</div>
         </div>
         
      </section>


      <section id="p2" className="ac4 pg5" style={{marginTop:50,display:'block'}}>
         <div class="ctn">
         <div class="area-mtp">
        
     <div class="area-a">
     <div>Data: {this.formatDate(b.data)}</div>
     <div>Rev: V1.2</div>
     <div>Número: AR 01</div>
     <div>Página 14 de 59</div>
     </div>
     
     <div class="area-b">
     <div>A.R. APRECIAÇÃO DE RISCOS</div>
     <div>{b.maquina.toUpperCase()}</div>
     </div>
     
     <div class="area-c">
     c
     </div>
     
     
         </div>
     
       

        
<div className='dados'>
  <div>ocorrência de tal defeito for significante. Sempre que razoavelmente praticável, o defeito isolado deve ser detectado durante ou antes da próxima solicitação da função de segurança.
  </div>
<div style={{display:'table',margin:'auto',marginTop:10}}>
  <img src={f11} style={{width:'100%',height:200}}/>
</div>
<div>
  <strong>
  Categoria 4
  </strong>
</div>
<div>
Partes de sistemas de comando relacionadas à segurança, de categoria 4, devem ser projetadas de tal forma que:</div>
<div>
- Uma falha isolada em qualquer dessas partes relacionadas à segurança não leve à perda das funções de segurança, e
</div>
<div>
- A falha isolada seja detectada antes ou durante a próxima atuação sobre a função de segurança, como, por exemplo, imediatamente, ao ligar o comando, ao final do ciclo de operação da máquina.
</div>
<div>

Se essa detecção não for possível, o acúmulo de defeitos não pode levar à perda das funções de segurança.
</div>

<div style={{display:'table',margin:'auto',marginTop:10}}>
  <img src={f12} style={{width:'100%',height:200}}/>
</div>
<div style={{marginBottom:20}}>
  <strong>
  Categoria 3
  </strong>
</div>
<div>
Partes relacionadas à segurança de sistemas de comando de categoria 3 devem ser projetadas de tal forma que um defeito isolado, em qualquer dessas partes, não leve à perda das funções de segurança. Defeitos de modos comuns devem ser considerados, quando a probabilidade da
</div>
</div>
        


           <div>{b.site}</div>
         </div>
         
      </section>


      <section id="p2" className="ac4 pg5" style={{display:'block'}}>
         <div class="ctn">
         <div class="area-mtp">
        
     <div class="area-a">
     <div>Data: {this.formatDate(b.data)}</div>
     <div>Rev: V1.2</div>
     <div>Número: AR 01</div>
     <div>Página 15 de 59</div>
     </div>
     
     <div class="area-b">
     <div>A.R. APRECIAÇÃO DE RISCOS</div>
     <div>{b.maquina.toUpperCase()}</div>
     </div>
     
     <div class="area-c">
     c
     </div>
     
     
         </div>
     
       

        
 <div className='dados'>
  <div className='centro'>
    <img src={f5} style={{width:'100%',height:'60%'}}/>
  </div>
  <div>Este gráfico de riscos pode ser utilizado para estimar um índice de riscos principalmente para situações perigosas que podem induzir danos agudos.</div>
 <div style={{padding:15}}>
  <div><strong>A. Um índice de risco de 1 e 2 corresponde a um risco baixo;</strong></div>
  <div><strong>B. Um índice de risco de 3 a 4 corresponde a um risco médio;</strong></div>
  <div><strong>C. Um índice de risco de 5 e 6 corresponde a um risco alto;</strong></div>
 </div>
 <div>Depois de uma análise dos possíveis meios para reduzir o risco, ele é estimado novamente para o projeto final utilizando o mesmo gráfico de riscos da mesma forma que para o projeto inicial. </div>
 <div className='centro'>
    <img src={f6} style={{width:'100%',height:'100%'}}/>
  </div>
 </div>
        


           <div>{b.site}</div>
         </div>
         
      </section>



      <section id="p2" className="ac4 pg5" style={{display:'block'}}>
         <div class="ctn">
         <div class="area-mtp">
        
     <div class="area-a">
     <div>Data: {this.formatDate(b.data)}</div>
     <div>Rev: V1.2</div>
     <div>Número: AR 01</div>
     <div>Página 16 de 59</div>
     </div>
     
     <div class="area-b">
     <div>A.R. APRECIAÇÃO DE RISCOS</div>
     <div>{b.maquina.toUpperCase()}</div>
     </div>
     
     <div class="area-c">
     c
     </div>
     
     
         </div>
     
       

        
         <div className='dados'>
<div>         Estabelecendo uma relação de semelhança entre HRN metodologia aplicada e as normas técnicas <strong>NBR 14153: 2022, ISO/TR 14.121-2: 2018 e ISO 12.100: 2013</strong> é possível chegar a uma conclusão para a determinação de categoria de segurança:</div>
        
        <div style={{marginTop:10,marginBottom:10}}>Pode se considerar um risco baixo a categoria 1, e um risco médio quando se encaixa na categoria 2 e um risco alto, quando está classificado na categoria 3 ou 4.</div>
        <div style={{textAlign:'center'}}>
    <strong>    ISO 13849-1: 2019</strong>
        </div>
        <div>
        Qualquer que seja o tipo de dispositivo de proteção escolhido, deve-se lembrar que um “sistema relacionado à segurança” pode conter muitos elementos incluindo o dispositivo de proteção, fiação, dispositivo de chaveamento e às vezes, peças do sistema de controle operacional da máquina. Todos esses elementos do sistema (incluindo proteções, montagem, fios, etc.) devem ter características de desempenho adequadas, relevantes ao seu princípio de projeto e tecnologia. Os <strong>IEC/EM 62061 e EM ISO 13849-1</strong> classificam os níveis hierárquicos de desempenho das peças relacionadas à segurança dos sistemas de controle e proporcionam métodos de avaliação de risco em seus anexos para determinar os requisitos de integridade de um sistema de proteção. O <strong>EN ISO 13849-1: 2015</strong> fornece um gráfico de risco aumentado em seu anexo A.
        </div>
         </div>
        


           <div>{b.site}</div>
         </div>
         
      </section>



      <section id="p2" className="ac4 pg5" style={{display:'block'}}>
         <div class="ctn">
         <div class="area-mtp">
        
     <div class="area-a">
     <div>Data: {this.formatDate(b.data)}</div>
     <div>Rev: V1.2</div>
     <div>Número: AR 01</div>
     <div>Página 17 de 59</div>
     </div>
     
     <div class="area-b">
     <div>A.R. APRECIAÇÃO DE RISCOS</div>
     <div>{b.maquina.toUpperCase()}</div>
     </div>
     
     <div class="area-c">
     c
     </div>
     
     
         </div>
     
       

         <div className='dados'>
  <div className='centro'>
    <img src={f7} style={{width:'100%',height:'100%'}}/>
  </div>

<div><strong>Onde:</strong></div>
<div style={{padding:10}}>
<div><strong>1-</strong> Ponto de partida para a avaliação de redução de risco.</div>
<div><strong>L-</strong> Baixa contribuição para a redução do risco.</div>
<div><strong>H-</strong> Alta contribuição para a redução do risco.</div>
<div><strong>PLr-</strong> Performance Level requerido.</div>

</div>
<div style={{textAlign:'center'}}><strong>S – Severidade do ferimento</strong></div>
<div style={{padding:10}}>
<div><strong>S1-</strong>Ferimento leve (normalmente reversível)</div>
<div><strong>S2-</strong> Ferimento sério (normalmente irreversível) incluindo morte.</div>
</div>




<div style={{textAlign:'center'}}><strong>F – Frequência e/ou tempo de exposição ao perigo.</strong></div>
<div style={{padding:10}}>
<div><strong>F1-</strong>Raro a relativamente frequente e/ou baixo tempo de exposição.</div>
<div><strong>F2-</strong> Frequente a contínuo e/ou tempo de exposição longo.</div>
</div>

<div style={{textAlign:'center'}}><strong>P – Possibilidade de evitar o perigo.</strong></div>
<div style={{padding:10}}>
<div><strong>P1-</strong>Possível sob condições especificas.</div>
<div><strong>P2-</strong>Possível sob condições especificas.</div>
</div>

<div style={{textAlign:'center'}}><strong>EM/IEC 62061 – SIL</strong></div>

</div>
        


           <div>{b.site}</div>
         </div>
         
      </section>



      <section id="p2" className="ac4 pg5" style={{display:'block'}}>
         <div class="ctn">
         <div class="area-mtp">
        
     <div class="area-a">
     <div>Data: {this.formatDate(b.data)}</div>
     <div>Rev: V1.2</div>
     <div>Número: AR 01</div>
     <div>Página 18 de 59</div>
     </div>
     
     <div class="area-b">
     <div>A.R. APRECIAÇÃO DE RISCOS</div>
     <div>{b.maquina.toUpperCase()}</div>
     </div>
     
     <div class="area-c">
     c
     </div>
     
     
         </div>
     
       

         <div className='dados'>
  <div>
  Descreve a quantidade de risco a ser reduzido e a capacidade de um sistema de controle de reduzir esse risco em termos de SIL (Nível de Integridade de Segurança). Há três SILs usados no setor de máquinas, o SIL 1 é o menor e o SIL 3 é o maior. Como o termo SIL é aplicado do mesmo modo em outros setores industriais, como o petroquímico, geração de energia e ferrovias, o IEC/EM 62061 é bastante útil quando a máquina é usada nesses setores.

  </div>
  <div className='centro'>
    <img src={g1} style={{width:'100%',height:300}}/>
  </div>

<div>
Relação entre os dois padrões e explica como a equivalência pode ser obtida entre PL (Nível de Desempenho) do EM ISO 13849-1 e SIL (Nível de Integridade de Segurança) do IEC EM 62061, ambos no nível de sistema e subsistema. Para demonstrar que ambos os padrões fornecem resultados equivalentes, o relatório exibe um sistema de segurança como exemplo, calculado de acordo com as metodologias de ambos os padrões.

</div>

<div style={{marginTop:20}}>
Em geral, quando PLe se faz necessário para a função de segurança ser implementada por um sistema de controle de segurança, não é normal depender somente de exclusões de falhas para atingir esse nível de desempenho. Isso depende da tecnologia usada e do ambiente operacional pretendido. Portanto, é essencial que o projetista tome cuidado adicional com o uso de exclusões de falhas à medida que a exigência de PL aumenta.
</div>



<div style={{textAlign:'center',marginBottom:25}}><strong>RELAÇÃO ENTRE NBR 14153 E A ISO 13849-1: 2019</strong></div>

<div>A norma ISO 13849-1 usa dados quantitativos de confiabilidade como parte do cálculo do PL alcançado pelas partes relacionadas com a segurança de um sistema de controle. Estes dados devem ter confiabilidade assegurada, pois estamos falando, por exemplo, de um MTTFd alto (tempo médio para ocorrência da falha perigosa) na faixa de 30 a 100 anos.</div>



<div>Na União Europeia a confiabilidade dos componentes de segurança está coberta pela avaliação da conformidade expressa na Diretiva Máquinas, mesmo assim, a migração dos conceitos da EM 954</div>


</div>
        


           <div>{b.site}</div>
         </div>
         
      </section>


      <section id="p2" className="ac4 pg5" style={{display:'block'}}>
         <div class="ctn">
         <div class="area-mtp">
        
     <div class="area-a">
     <div>Data: {this.formatDate(b.data)}</div>
     <div>Rev: V1.2</div>
     <div>Número: AR 01</div>
     <div>Página 19 de 59</div>
     </div>
     
     <div class="area-b">
     <div>A.R. APRECIAÇÃO DE RISCOS</div>
     <div>{b.maquina.toUpperCase()}</div>
     </div>
     
     <div class="area-c">
     c
     </div>
     
     
         </div>
     
       

         <div className='dados'>
  <div>
  (base da ABNT NBR 14153) para ISSO 13849 passou por um longo processo de maturação, inclusive com a convivência por mais de 2 anos, de opção para aplicação de qualquer uma das duas normas.
  </div>

  <div style={{textAlign:'center',marginBottom:25,marginTop:25}}><strong>RELAÇÃO ENTRE CATEGORIAS, DC E MTTFD DE CADA CANAL E O PL.</strong></div>


<div>
Conclui-se que para o alcance do nível de performance requerido, não basta apenas a arquitetura do sistema (categorias), mas também trabalhar as variáveis como a confiabilidade dos dados para as partes constituintes do sistema expresso no tempo médio para falha perigosa (MTTFd), a cobertura de diagnostico (DC) que representa a quantidade de monitoramento de falhas no sistema, proteção contra falhas de causa comum, proteção contra falhas sistemáticas e, sempre que necessário, requisitos específicos de software.
</div>
<br/>
<div>
O gráfico e a tabela abaixo procuram estabelecer, de forma simplificada, a relação entre as categorias de segurança e PL em função das variáveis elencadas.
</div>
  <div className='centro'>
    <img src={g2} style={{width:'100%',height:300}}/>
  </div>

  <div className='centro'>
    <img src={g3} style={{width:'80%',height:250}}/>
  </div>

</div>
        


           <div>{b.site}</div>
         </div>
         
      </section>

      <section id="p2" className="ac4 pg5" style={{display:'block'}}>
         <div class="ctn">
         <div class="area-mtp">
        
     <div class="area-a">
     <div>Data: {this.formatDate(b.data)}</div>
     <div>Rev: V1.2</div>
     <div>Número: AR 01</div>
     <div>Página 20 de 59</div>
     </div>
     
     <div class="area-b">
     <div>A.R. APRECIAÇÃO DE RISCOS</div>
     <div>{b.maquina.toUpperCase()}</div>
     </div>
     
     <div class="area-c">
     c
     </div>
     
     
         </div>
     
       

         <div className='dados'>
  
  <div className='centro'>
    <img src={g4} style={{width:'80%',height:250}}/>
  </div>

</div>
        


           <div>{b.site}</div>
         </div>
         
      </section>


      <section id="p2" className="ac4 pg5" style={{display:'block'}}>
         <div class="ctn">
         <div class="area-mtp">
        
     <div class="area-a">
     <div>Data: {this.formatDate(b.data)}</div>
     <div>Rev: V1.2</div>
     <div>Número: AR 01</div>
     <div>Página 21 de 59</div>
     </div>
     
     <div class="area-b">
     <div>A.R. APRECIAÇÃO DE RISCOS</div>
     <div>{b.maquina.toUpperCase()}</div>
     </div>
     
     <div class="area-c">
     c
     </div>
     
     
         </div>
     
       

<div style={{
  display:'table',
  margin:'auto',
  width:'100%'
}}>
  
<table className="table ntbl table-bordered">
<thead> 
<tr>
            <th rowspan="2">DOCUMENTOS</th>
            <th colspan="3">STATUS</th>
            <th rowspan="2">OBSERVAÇÕES</th>
</tr>
<tr>
            <th>OK</th>
            <th>NÃO OK</th>
            <th>N.A.</th>
        </tr>
 </thead>
  <tbody>
  {[
  "ANOTAÇÃO DE RESPONSABILIDADE TÉCNICA",
  "CHECKLIST DE MANUTENÇÃO PREVENTIVA",
  "CHECKLIST DE SEGURANÇA",
  "ART DIAGRAMA DE CIRCUITOS ELÉTRICOS",
  "DIAGRAMA HIDRÁULICO",
  "DIAGRAMA PNEUMÁTICO",
  "INSTRUÇÕES DE TRABALHO",
  "LAUDO DE ATERRAMENTO",
  "LAUDO DE TEMPO DE PARADA",
  "MANUAL DE INSTRUÇÕES DE MANUTENÇÃO",
  "MANUAL DE INSTRUÇÕES DE SEGURANÇA",
  "MANUAL DE INSTRUÇÕES OPERACIONAIS",
  "PLAQUETA DE IDENTIFICAÇÃO",
  "ART PROJETO MECÂNICO"
].map((item,index)=>{
var check = this.state.data.form.find(u=> u.e === `${index}`);
if(check != undefined){
var find = check;
}else{
var find = {}
}
return(<>

  <tr>
              <td>{item} 
  
  {index} 
  
  
              </td>
              <td>
              <label>
              
             <input type="radio"

              checked={find.marker === "0"}
          
             name={`${index}`} value={0}/>
              </label>
                 
              </td>
  
              <td class="highlight">
            
              <input type="radio" 
              checked={find.marker === "1"}
               name={`${index}`} value={1}/>
              </td>
  
              <td>
              <input 
                      checked={find.marker === "2"}
              type="radio" name={`${index}`} value={2}/>
              </td>
              
              <td class="highlight">Não evidenciado</td>
          </tr>
    </>)
})}
  </tbody>
</table>

</div>
        


           <div>{b.site}</div>
         </div>
         
      </section>


{this.state.data.images.length > 0 && <>
{this.state.data.images.map(item=><>
  <section id="p2" className="ac4 pg5" style={{display:'block'}}>
         <div class="ctn">
         <div class="area-mtp">
        
     <div class="area-a">
     <div>Data: {this.formatDate(b.data)}</div>
     <div>Rev: V1.2</div>
     <div>Número: AR 01</div>
     <div>Página {`{count + this}`} de {`{maxPage}`}</div>
     </div>
     
     <div class="area-b">
     <div>A.R. APRECIAÇÃO DE RISCOS</div>
     <div>{b.maquina.toUpperCase()}</div>
     </div>
     
     <div class="area-c">
     c
     </div>
     
     
         </div>
     
       

<div>
  <img src={`https://api.hrnmachine.ind.br/img/`+item.img}/>
</div>
        


           <div style={{textAlign:'center'}}>{b.site}</div>
         </div>
         
      </section>
</>)}
</>}



<section id="p2" className="ac4 pg5" style={{display:'block'}}>
         <div class="ctn">
         <div class="area-mtp">
        
     <div class="area-a">
     <div>Data: {this.formatDate(b.data)}</div>
     <div>Rev: V1.2</div>
     <div>Número: AR 01</div>
     <div>Página 21 de 59</div>
     </div>
     
     <div class="area-b">
     <div>A.R. APRECIAÇÃO DE RISCOS</div>
     <div>{b.maquina.toUpperCase()}</div>
     </div>
     
     <div class="area-c">
     c
     </div>
     
     
         </div>
     

       

<div className='dados'>
  
<div>
  A máquina foi avaliada e categorizada como categoria <strong>{this.state.ctg}</strong> e Performance Level Required <strong>{this.state.letra.toUpperCase()}</strong> de segurança conforme: EM ISO 13849-1/NBR 14153.
</div>

<div className='area-diag'>

<div className='diagrama'>
<TreeNode
save={this.state.data.diagram} node={{
  name: 'Start',
  children: [
    {
      name: 's1',
      children: [
        {
          name: 'f1',
          children: [
            { name: 'p1' },
            { name: 'p2' },
          ],
        },
        { name: 'f2',
          children: [
            { name: 'p1' },
            { name: 'p2' },
          ],

        },
      ],
    },
    {
      name: 's2',
      children: [
        {
          name: 'f1',
          children: [
            { name: 'p1' },
            { name: 'p2' },
          ],
        },
        {
          name: 'f2',
          children: [
            { name: 'p1' },
            { name: 'p2' },
          ],
        },
      ],
    },
  ],
}}
/>
</div>


</div>

   
     
  
         <img src={g6} style={{width:'100%',height:400}} />
</div>
        


           <div>{b.site}</div>
         </div>
         
</section>

<section id="p2" className="ac4 pg5" style={{display:'block'}}>
         <div class="ctn">
         <div class="area-mtp">
        
     <div class="area-a">
     <div>Data: {this.formatDate(b.data)}</div>
     <div>Rev: V1.2</div>
     <div>Número: AR 01</div>
     <div>Página 21 de 59</div>
     </div>
     
     <div class="area-b">
     <div>A.R. APRECIAÇÃO DE RISCOS</div>
     <div>{b.maquina.toUpperCase()}</div>
     </div>
     
     <div class="area-c">
     c
     </div>
     
     
         </div>
     
       

<div className='dados'>


{this.state.selectedItem}

<table className='frm2' border="1">
        <thead>
          <tr className='alinha-f2'>
            {this.state.tabela[0].map((header, index) => (
              <th key={index}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {this.state.tabela.slice(1).map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.map((item, colIndex) => (
                <td key={colIndex}>
                  <button
                    
                    style={{
                      display:'flex',
                      justifyContent:'center',
                      alignItems:'center',
                      backgroundColor: selectedItems.includes(`${rowIndex}-${colIndex}`) ? 'yellow' : 'transparent',
                      borderRadius: '50%',
                      padding: '10px',
                      width: '40px',
                      height: '40px'
                    }}
                  >
                    {item}
                  </button>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
</table>



   
      
         <img src={g8} style={{width:'80%',height:400}} />
</div>
        


           <div>{b.site}</div>
         </div>
         
</section>



<section id="p2" className="ac4 pg5" style={{display:'block'}}>
         <div class="ctn">
         <div class="area-mtp">
        
     <div class="area-a">
     <div>Data: {this.formatDate(b.data)}</div>
     <div>Rev: V1.2</div>
     <div>Número: AR 01</div>
     <div>Página 21 de 59</div>
     </div>
     
     <div class="area-b">
     <div>A.R. APRECIAÇÃO DE RISCOS</div>
     <div>{b.maquina.toUpperCase()}</div>
     </div>
     
     <div class="area-c">
     c
     </div>
     
     
         </div>
     

       

<div className='dados'>
<div>
  <strong>
 12 - Arranjo físico das instalações – Geral
  </strong>
</div>

<div style={{marginLeft:50}}>
  <strong>
  12.1 – Arranjo físico das instalações – Geral
  </strong>
</div>
<div>
  <p>
  Nos locais de instalações de máquinas e equipamentos, as áreas de circulação devem ser devidamente demarcadas e em conformidade com as normas técnicas oficias vigentes.
  </p>
</div>
<TextView vz="v" type="text"
 code="dfasgkjohh" 
 data={this.state.TextView}
/>
<ImageView  code="g5th4gh" data={this.state.ImageView}/> 


<RiskAppreciationTable
  code="5s6df6"
  data={this.state.FormHrn}
  title="APRECIAÇÃO DE RISCO ANTES DAS AÇÕES DE MELHORIAS"/>


<div style={{border:'solid 1px',marginTop:20}}>
  <div style={{background:"#b4c6e7",textAlign:'center'}}>
    <strong>AÇÕES PROPOSTAS COMO SUGESTÃO DE ADEQUAÇÃO</strong>
  </div>

<span>
<TextView vz="v" type="text"
 code="204rtgd" 
 data={this.state.TextView}
/>
</span>
 



 
</div>

<RiskAppreciationTable
                            code="5fdx56"
                            data={this.state.FormHrn}
                            title="APRECIAÇÃO DE RISCO DEPOIS DAS AÇÕES DAS MELHORIAS"/>



<div style={{marginTop:15}}>
  <strong>
  12.2 Instalações e dispositivos elétricos – Geral
  </strong>
</div>

<div>
  <p>
  Os circuitos elétricos de comando e potência das máquinas e equipamentos devem ser projetados e mantidos de modo a prevenir, por meios seguros, os perigos de choque elétrico, incêndio, explosão e outros tipos de acidentes, conforme previsto nas normas técnicas oficiais e, na falta dessas, nas normas internacionais aplicáveis.
  </p>
</div>


<div>

</div>





<div style={{marginTop:10}}>
<span>
  {this.TextView('5dzfg1')}
</span>
</div>




</div>
        


           <div>{b.site}</div>
         </div>
         
</section>



<section id="p2" className="ac4 pg5" style={{display:'block'}}>
         <div class="ctn">
         <div class="area-mtp">
        
     <div class="area-a">
     <div>Data: {this.formatDate(b.data)}</div>
     <div>Rev: V1.2</div>
     <div>Número: AR 01</div>
     <div>Página 21 de 59</div>
     </div>
     
     <div class="area-b">
     <div>A.R. APRECIAÇÃO DE RISCOS</div>
     <div>{b.maquina.toUpperCase()}</div>
     </div>
     
     <div class="area-c">
     c
     </div>
     
     
         </div>
     

       

<div className='dados'>

<TextView vz="v" type="text"
 code="2354r65fdh" 
 data={this.state.TextView}
/>


<div>
  <strong>
  SITUAÇÃO ATUALL: 
  </strong>
</div>


{this.state.groupi[0] != undefined && <>
{this.state.groupi[0].list.map((item)=><>
<img src={`${apiUrl}/img/${item.img}`}/>

</>)}
</>}


<div style={{marginTop:10}}>
<span>
  {this.TextView('fg54jd')}
</span>
</div>




</div>
        


           <div>{b.site}</div>
         </div>
         
</section>



{this.state.groupi.map((item,index)=><>

{index > 0 && <>
  <section id="p2" className="ac4 pg5" style={{display:'block'}}>
         <div class="ctn">
         <div class="area-mtp">
        
     <div class="area-a">
     <div>Data: {this.formatDate(b.data)}</div>
     <div>Rev: V1.2</div>
     <div>Número: AR 01</div>
     <div>Página 21 de 59</div>
     </div>
     
     <div class="area-b">
     <div>A.R. APRECIAÇÃO DE RISCOS</div>
     <div>{b.maquina.toUpperCase()}</div>
     </div>
     
     <div class="area-c">
     c
     </div>
     
     
         </div>
     

       

<div className='dados'>


{item.list.length > 0 ? <>
{item.list.map((item)=><>
  <img src={`${apiUrl}/img/${item.img}`}/>
</>)}
</> : <>Não há imagens!</>}



</div>
        


           <div>{b.site}</div>
         </div>
         
</section>
</>}


</>)}




<section id="p2" className="ac4 pg5" style={{display:'block'}}>
         <div class="ctn">
         <div class="area-mtp">
        
     <div class="area-a">
     <div>Data: {this.formatDate(b.data)}</div>
     <div>Rev: V1.2</div>
     <div>Número: AR 01</div>
     <div>Página 21 de 59</div>
     </div>
     
     <div class="area-b">
     <div>A.R. APRECIAÇÃO DE RISCOS</div>
     <div>{b.maquina.toUpperCase()}</div>
     </div>
     
     <div class="area-c">
     c
     </div>
     
     
         </div>
     

       

<div className='dados'>



<img src={`${apiUrl}/img/${this.ViewImage('dsfy56n')}`} />




<div>
<span>
  {this.TextView('f65g4ghn')}
</span>
</div>


<div style={{display:'flex',marginTop:10}}>
<img src={`${apiUrl}/img/${this.ViewImage('sdf65h4')}`} />
<img src={`${apiUrl}/img/${this.ViewImage('5xdf4hb')}`} />
</div>
<div>
<img src={`${apiUrl}/img/${this.ViewImage('987h65f')}`} />
</div>







</div>
        


           <div>{b.site}</div>
         </div>
         
</section>


<section id="p2" className="ac4 pg5" style={{display:'block'}}>
         <div class="ctn">
         <div class="area-mtp">
        
     <div class="area-a">
     <div>Data: {this.formatDate(b.data)}</div>
     <div>Rev: V1.2</div>
     <div>Número: AR 01</div>
     <div>Página 21 de 59</div>
     </div>
     
     <div class="area-b">
     <div>A.R. APRECIAÇÃO DE RISCOS</div>
     <div>{b.maquina.toUpperCase()}</div>
     </div>
     
     <div class="area-c">
     c
     </div>
     
     
         </div>
     

       

<div className='dados'>



<img src={`${apiUrl}/img/${this.ViewImage('sr65h84')}`} />

<div>
<span>
  {this.TextView('35r57tv')}
</span>
</div>
<div>
<img src={`${apiUrl}/img/${this.ViewImage('d6f54gdh')}`} />
</div>

<div>
  <span>
  {this.TextView('65d4fh1gh')}
  </span>
</div>

<div>
  <span>
  {this.TextView('3s54rh2')}
  </span>
</div>











</div>
        


           <div>{b.site}</div>
         </div>
         
</section>


<section id="p2" className="ac4 pg5" style={{display:'none  '}}>
         <div class="ctn">
         <div class="area-mtp">
        
     <div class="area-a">
     <div>Data: {this.formatDate(b.data)}</div>
     <div>Rev: V1.2</div>
     <div>Número: AR 01</div>
     <div>Página 21 de 59</div>
     </div>
     
     <div class="area-b">
     <div>A.R. APRECIAÇÃO DE RISCOS</div>
     <div>{b.maquina.toUpperCase()}</div>
     </div>
     
     <div class="area-c">
     c
     </div>
     
     
         </div>
     

       

<div className='dados'>




<div  style={{
                          
                     
                            }}>
                            <ImageView  code="w45f94" data={this.state.ImageView}/>  
                            <ImageView  code="35d4fg" data={this.state.ImageView}/>  
                            <ImageView  code="324das" data={this.state.ImageView}/>  
                            <ImageView  code="32a41r" data={this.state.ImageView}/>
                            <ImageView  code="kuyj65" data={this.state.ImageView}/>  
                         

                            </div>



</div>
        


           <div>{b.site}</div>
         </div>
         
</section>


<section pagina="14.2" id="p2" className="ac4 pg5" style={{display:'block'}}>
         <div class="ctn">
         <div class="area-mtp">
        
     <div class="area-a">
     <div>Data: {this.formatDate(b.data)}</div>
     <div>Rev: V1.2</div>
     <div>Número: AR 01</div>
     <div>Página 21 de 59</div>
     </div>
     
     <div class="area-b">
     <div>A.R. APRECIAÇÃO DE RISCOS</div>
     <div>{b.maquina.toUpperCase()}</div>
     </div>
     
     <div class="area-c">
     c
     </div>
     
     
         </div>
     

       

<div className='dados'>



<div>
<span>
  {this.TextView('654xh65')}
</span>
</div>



<ImageView  code="sd542df" data={this.state.ImageView}/>  


<TextView vz="v" type="input"
 code="mn16tyf5" 
 data={this.state.TextView}
/> 

<ImageView  code="qwer46" data={this.state.ImageView}/>  


<TextView type="input"
vz="v"
 code="321rt6t" 
 data={this.state.TextView}
/> 
</div>
<div>{b.site}</div>
</div>     
</section>


<section pagina="15" id="p2" className="ac4 pg5" style={{display:'block'}}>
         <div class="ctn">
         <div class="area-mtp">
        
     <div class="area-a">
     <div>Data: {this.formatDate(b.data)}</div>
     <div>Rev: V1.2</div>
     <div>Número: AR 01</div>
     <div>Página 21 de 59</div>
     </div>
     
     <div class="area-b">
     <div>A.R. APRECIAÇÃO DE RISCOS</div>
     <div>{b.maquina.toUpperCase()}</div>
     </div>
     
     <div class="area-c">
     c
     </div>
     
     
         </div>
     

       

<div className='dados'>






<ImageView  code="654srth" data={this.state.ImageView}/>  


<TextView vz="v" type="input"
 code="e3455yf" 
 data={this.state.TextView}
/> 

<ImageView  code="zq351hd" data={this.state.ImageView}/>  


<div>
  <strong>
  SITUAÇÃO ATUAL:
  </strong>
</div>



</div>
<div>{b.site}</div>
</div>     
</section>



<section pagina="16" parte="8" id="p2" className="ac4 pg5" style={{display:'block'}}>
         <div class="ctn">
         <div class="area-mtp">
        
     <div class="area-a">
     <div>Data: {this.formatDate(b.data)}</div>
     <div>Rev: V1.2</div>
     <div>Número: AR 01</div>
     <div>Página 21 de 59</div>
     </div>
     
     <div class="area-b">
     <div>A.R. APRECIAÇÃO DE RISCOS</div>
     <div>{b.maquina.toUpperCase()}</div>
     </div>
     
     <div class="area-c">
     c
     </div>
     
     
         </div>
     

       

<div className='dados'>




<div style={{border:"solid 1px",marginTop:15}}>
  <div style={{background:"",textAlign:'center'}}>
    <strong>
    AÇÕES PROPOSTAS COMO SUGESTÃO DE ADEQUAÇÃO
    </strong>
  </div>
  <div>
<div>
  <p>
  <TextView vz="v" type="input"
 code="35rs4hrt" 
 data={this.state.TextView}
/>
  </p>
</div>





<ImageView  code="654sdfv" data={this.state.ImageView}/>

<div>
  <p>
  <TextView vz="v" type="text"
 code="32d4rhgr" 
 data={this.state.TextView}
/>


<div style={{marginTop:10}}>
  <p>
  <strong>12.3.2. Devem</strong> ser aterrados, conforme as normas técnicas oficiais vigentes, as carcaças, invólucros, blindagens ou partes condutoras das máquinas e equipamentos que não façam parte dos circuitos elétricos, mas que possam ficar sob tensão.

  </p>
</div>


<TextView vz="v" type="input"
 code="324drg35" 
 data={this.state.TextView}
/>


  </p>
</div>


<ImageView  code="654rtdsr6y5" data={this.state.ImageView}/>      


<TextView vz="v" type="text"
 code="324hgf7" 
 data={this.state.TextView}
/>  



  </div>
</div>

<RiskAppreciationTable 
                            code="58sdr4h"
                            data={this.state.FormHrn}
                            title="APRECIAÇÃO DE RISCO DEPOIS DAS AÇÕES DAS MELHORIAS"/>


<div style={{textAlign:'center',marginTop:15}}>
  <strong>
  12. 3 Dispositivo de partida, acionamento e parada – Geral
  </strong>
</div>

<TextView vz="v" type="input"
 code="324r685r89" 
 data={this.state.TextView}
/>

</div>
        


           <div>{b.site}</div>
         </div>
         
</section>

aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa

<section pagina="17" id="p2"  className="ac4 pg5" style={{display:'block'}}>
         <div class="ctn">
         <div class="area-mtp">
        
     <div class="area-a">
     <div>Data: {this.formatDate(b.data)}</div>
     <div>Rev: V1.2</div>
     <div>Número: AR 01</div>
     <div>Página 21 de 59</div>
     </div>
     
     <div class="area-b">
     <div>A.R. APRECIAÇÃO DE RISCOS</div>
     <div>{b.maquina.toUpperCase()}</div>
     </div>
     
     <div class="area-c">
     c
     </div>
     
     
         </div>
     

       

<div className='dados'>






<ImageView  code="321t65grt" data={this.state.ImageView}/>


<TextView vz="v" type="text"
            code="3465465uy" 
            data={this.state.TextView}
            />
   

   <div style={{display:'flex',marginTop:10}}>
<ImageView  code="3546dfhf" data={this.state.ImageView}/>
<ImageView  code="649685df" data={this.state.ImageView}/>
</div>



<TextView vz="v" type="text"
            code="6546845y" 
            data={this.state.TextView}
  />

<ImageView  code="2a654gf" data={this.state.ImageView}/>


         <TextView vz="v" type="input"
            code="35df4h65" 
            data={this.state.TextView}
            />










</div>
        


           <div>{b.site}</div>
         </div>
         
</section>


<section pagina="18.2" id="p2" className="ac4 pg5" style={{display:'block'}}>
         <div class="ctn">
         <div class="area-mtp">
        
     <div class="area-a">
     <div>Data: {this.formatDate(b.data)}</div>
     <div>Rev: V1.2</div>
     <div>Número: AR 01</div>
     <div>Página 21 de 59</div>
     </div>
     
     <div class="area-b">
     <div>A.R. APRECIAÇÃO DE RISCOS</div>
     <div>{b.maquina.toUpperCase()}</div>
     </div>
     
     <div class="area-c">
     c
     </div>
     
     
         </div>
     

       

<div className='dados'>



<ImageView  code="2164hdfg" data={this.state.ImageView}/>



<TextView vz="v" type="text"
   code="df65g465" 
   data={this.state.TextView}
   />

<ImageView  code="324tyhxdf" data={this.state.ImageView}/>



<TextView vz="v" type="text"
   code="re354ty" 
   data={this.state.TextView}
   />


<ImageView  code="324retfd" data={this.state.ImageView}/>



<TextView vz="v" type="text"
code="e52ydfsyg" 
data={this.state.TextView}
/>







<ImageView  code="654fthjfg" data={this.state.ImageView}/>







</div>
        


           <div>{b.site}</div>
         </div>
         
</section>


<section pagina="19" id="p2" className="ac4 pg5" style={{display:'block'}}>
         <div class="ctn">
         <div class="area-mtp">
        
     <div class="area-a">
     <div>Data: {this.formatDate(b.data)}</div>
     <div>Rev: V1.2</div>
     <div>Número: AR 01</div>
     <div>Página 21 de 59</div>
     </div>
     
     <div class="area-b">
     <div>A.R. APRECIAÇÃO DE RISCOS</div>
     <div>{b.maquina.toUpperCase()}</div>
     </div>
     
     <div class="area-c">
     c
     </div>
     
     
         </div>
     

       

<div className='dados'>


<TextView vz="v" type="text"
            code="324tydu6" 
            data={this.state.TextView}
      />

<TextView vz="v" type="input"
            code="3254dfuf" 
            data={this.state.TextView}
      />

      <ImageView  code="324fghfsdt" data={this.state.ImageView}/>

      <TextView vz="v" type="input"
            code="32srtysf" 
            data={this.state.TextView}
      />
<ImageView  code="6354rdshdt" data={this.state.ImageView}/>

         <TextView vz="v" type="input"
            code="32dgdfgt" 
            data={this.state.TextView}
            />




</div>
        


           <div>{b.site}</div>
         </div>
         
</section>

<section pagina="20" id="p2" className="ac4 pg5" style={{display:'block'}}>
         <div class="ctn">
         <div class="area-mtp">
        
     <div class="area-a">
     <div>Data: {this.formatDate(b.data)}</div>
     <div>Rev: V1.2</div>
     <div>Número: AR 01</div>
     <div>Página 21 de 59</div>
     </div>
     
     <div class="area-b">
     <div>A.R. APRECIAÇÃO DE RISCOS</div>
     <div>{b.maquina.toUpperCase()}</div>
     </div>
     
     <div class="area-c">
     c
     </div>
     
     
         </div>
     

       

<div className='dados'>

<ImageView  code="32135tdr" data={this.state.ImageView}/>

<TextView vz="v" type="text"
            code="3254rdahn" 
            data={this.state.TextView}
      />


</div>
        


           <div>{b.site}</div>
         </div>
         
</section>


<section id="p2" className="ac4 pg5" style={{display:'block'}}>
         <div class="ctn">
         <div class="area-mtp">
        
     <div class="area-a">
     <div>Data: {this.formatDate(b.data)}</div>
     <div>Rev: V1.2</div>
     <div>Número: AR 01</div>
     <div>Página 21 de 59</div>
     </div>
     
     <div class="area-b">
     <div>A.R. APRECIAÇÃO DE RISCOS</div>
     <div>{b.maquina.toUpperCase()}</div>
     </div>
     
     <div class="area-c">
     c
     </div>
     
     
         </div>
     

       

<div className='dados'>

<img src={g9}/>


</div>
        


           <div>{b.site}</div>
         </div>
         
</section>






<section parte="9" id="p2" className="ac4 pg5" style={{display:'block'}}>

         <div class="ctn">
         <div class="area-mtp">
        
     <div class="area-a">
     <div>Data: {this.formatDate(b.data)}</div>
     <div>Rev: V1.2</div>
     <div>Número: AR 01</div>
     <div>Página 21 de 59</div>
     </div>
     
     <div class="area-b">
     <div>A.R. APRECIAÇÃO DE RISCOS</div>
     <div>{b.maquina.toUpperCase()}</div>
     </div>
     
     <div class="area-c">
     c
     </div>
     
     
         </div>
     

       

         <div className='dados'>
         <RiskAppreciationTable 
code="54dfg6"
data={this.state.FormHrn}
resp={(value)=>{
 
}}
title="APRECIAÇÃO DE RISCO ANTES DAS AÇÕES DE MELHORIAS"/>



<div style={{border:'solid 1px',marginTop:15}}>
<div style={{textAlign:'center'}}>
  <strong>AÇÕES PROPOSTAS COMO SUGESTÃO DE ADEQUAÇÃO</strong>
</div>

<div style={{padding:4}}>
<TextView vz="v" type="text"
 code="xf5d4h" 
 data={this.state.TextView}
/>
</div>
</div>


<RiskAppreciationTable
                            code="fg56j4"
                            data={this.state.FormHrn}
                            title="APRECIAÇÃO DE RISCO DEPOIS DAS AÇÕES DAS MELHORIAS"/>



<div style={{marginLeft:20,marginTop:20}}>
<strong>
12.4    Sistemas de Seguranças
</strong>
</div>

<div>
  <strong>
  SITUAÇÃO ATUAL:
  </strong>
</div>

<div>
  <p>
  <TextView vz="v" type="textarea"
 code="fgh5t2" 
 data={this.state.TextView}
/>
  </p>
</div>

<div>
  <p>
  <TextView vz="v" type="textarea"
 code="35dfghfgh54" 
 data={this.state.TextView}
/>
    
  </p>
</div>


<div style={{
                            display:'flex',
                     
                            }}>
                            <ImageView  code="rth54g" data={this.state.ImageView}/>  
                            <ImageView  code="c546hf" data={this.state.ImageView}/>  


                            </div>

<div>
  <p>
  <TextView vz="v" type="input"
 code="5dzfg1" 
 data={this.state.TextView}
/>
   
  </p>
</div>

         </div>
        




     
   



           <div>{b.site}</div>
         </div>
         
</section>



<section pagina="10" id="p2" className="ac4 pg5" style={{display:'block'}}>

         <div class="ctn">
         <div class="area-mtp">
        
     <div class="area-a">
     <div>Data: {this.formatDate(b.data)}</div>
     <div>Rev: V1.2</div>
     <div>Número: AR 01</div>
     <div>Página 21 de 59</div>
     </div>
     
     <div class="area-b">
     <div>A.R. APRECIAÇÃO DE RISCOS</div>
     <div>{b.maquina.toUpperCase()}</div>
     </div>
     
     <div class="area-c">
     c
     </div>
     
     
         </div>
     

       

         <div className='dados'>


      <ImageView  code="5ddsf" data={this.state.ImageView}/>   





<TextView vz="v" type="input"
code="fgth65" 
data={this.state.TextView}
/>

                      <div style={{
                      display:'flex',
               
                      }}>
                      <ImageView  code="c6g5j4" data={this.state.ImageView}/>  
                      <ImageView  code="nxdfh5" data={this.state.ImageView}/>  


                      </div>
                      


                      <TextView vz="v" type="input"
code="fg54jd" 
data={this.state.TextView}
/>
         </div>
        




     
   



           <div>{b.site}</div>
         </div>
         
</section>



<section pagina="11" id="p2" className="ac4 pg5" style={{display:'block'}}>

         <div class="ctn">
         <div class="area-mtp">
        
     <div class="area-a">
     <div>Data: {this.formatDate(b.data)}</div>
     <div>Rev: V1.2</div>
     <div>Número: AR 01</div>
     <div>Página 21 de 59</div>
     </div>
     
     <div class="area-b">
     <div>A.R. APRECIAÇÃO DE RISCOS</div>
     <div>{b.maquina.toUpperCase()}</div>
     </div>
     
     <div class="area-c">
     c
     </div>
     
     
         </div>
     

       

         <div className='dados'>

         <div style={{
                            display:'flex',
                     
                            }}>
                            <ImageView  code="dsfy56n" data={this.state.ImageView}/>  
                            

                            </div>


 <TextView vz="v" type="input"
 code="f65g4ghn" 
 data={this.state.TextView}
/>
                           
                              <div style={{
                            display:'flex',
                     
                            }}>
                            <ImageView  code="sdf65h4" data={this.state.ImageView}/>  
                            <ImageView  code="5xdf4hb" data={this.state.ImageView}/>  
                            <ImageView  code="987h65f" data={this.state.ImageView}/> 

                           
                           
                           
                            </div>


         </div>
        




     
   



           <div>{b.site}</div>
         </div>
         
</section>


<section pagina="12" id="p2" className="ac4 pg5" style={{display:'block'}}>

         <div class="ctn">
         <div class="area-mtp">
        
     <div class="area-a">
     <div>Data: {this.formatDate(b.data)}</div>
     <div>Rev: V1.2</div>
     <div>Número: AR 01</div>
     <div>Página 21 de 59</div>
     </div>
     
     <div class="area-b">
     <div>A.R. APRECIAÇÃO DE RISCOS</div>
     <div>{b.maquina.toUpperCase()}</div>
     </div>
     
     <div class="area-c">
     c
     </div>
     
     
         </div>
     

       
         <div className='dados'>

         <ImageView  code="sr65h84" data={this.state.ImageView}/>


<div>
  <p>
  <TextView vz="v" type="input"
code="35r57tv" 
data={this.state.TextView}
/>
  </p>
</div>





<ImageView  code="d6f54gdh" data={this.state.ImageView}/>



<div>
  <p>
  <TextView vz="v" type="input"
code="65d4fh1gh" 
data={this.state.TextView}
/>
  </p>
</div>

<div>
  <p>
  <TextView vz="v" type="textarea"
code="3s54rh2" 
data={this.state.TextView}
/>
  </p>
</div>


         </div>
        




     
   



           <div>{b.site}</div>
         </div>
         
</section>

<section pagina="13" id="p2" className="ac4 pg5" style={{display:'block'}}>

         <div class="ctn">
         <div class="area-mtp">
        
     <div class="area-a">
     <div>Data: {this.formatDate(b.data)}</div>
     <div>Rev: V1.2</div>
     <div>Número: AR 01</div>
     <div>Página 21 de 59</div>
     </div>
     
     <div class="area-b">
     <div>A.R. APRECIAÇÃO DE RISCOS</div>
     <div>{b.maquina.toUpperCase()}</div>
     </div>
     
     <div class="area-c">
     c
     </div>
     
     
         </div>
     
         <div className='dados' style={{display:'table',margin:'auto'}}>
         <div style={{display:'flex',}}>
                            <ImageView  code="w45f94" data={this.state.ImageView}/>  
                            <ImageView code="35d4fg" data={this.state.ImageView}/>  
           </div>
           <div style={{display:'flex',}}>
                            <ImageView code="324das" data={this.state.ImageView}/>  
                            <ImageView code="32a41r" data={this.state.ImageView}/>
                            </div>
                            <ImageView code="kuyj65" data={this.state.ImageView}/>  
                         

                     
         </div>
   
  
           <div>{b.site}</div>
         </div>
         
</section>

<section pagina="14" id="p2" className="ac4 pg5" style={{display:'block'}}>

         <div class="ctn">
         <div class="area-mtp">
        
     <div class="area-a">
     <div>Data: {this.formatDate(b.data)}</div>
     <div>Rev: V1.2</div>
     <div>Número: AR 01</div>
     <div>Página 21 de 59</div>
     </div>
     
     <div class="area-b">
     <div>A.R. APRECIAÇÃO DE RISCOS</div>
     <div>{b.maquina.toUpperCase()}</div>
     </div>
     
     <div class="area-c">
     c
     </div>
     
     
         </div>
     
         <div className='dados'>
        
                               
 <TextView vz="v" type="input"
 code="654xh65" 
 data={this.state.TextView}
/>                  
                                                           
<ImageView code="sd542df" data={this.state.ImageView}/>  



<TextView vz="v" type="input"
 code="mn16tyf5" 
 data={this.state.TextView}
/> 


<ImageView code="qwer46" data={this.state.ImageView}/>  


<TextView vz="v" type="input"
 code="321rt6t" 
 data={this.state.TextView}
/> 


                     
         </div>
   
  
           <div>{b.site}</div>
         </div>
         
</section>

<section pagina="15.2" id="p2" className="ac4 pg5" style={{display:'block'}}>

         <div class="ctn">
         <div class="area-mtp">
        
     <div class="area-a">
     <div>Data: {this.formatDate(b.data)}</div>
     <div>Rev: V1.2</div>
     <div>Número: AR 01</div>
     <div>Página 21 de 59</div>
     </div>
     
     <div class="area-b">
     <div>A.R. APRECIAÇÃO DE RISCOS</div>
     <div>{b.maquina.toUpperCase()}</div>
     </div>
     
     <div class="area-c">
     c
     </div>
     
     
         </div>
     
         <div className='dados'>
                                        
<ImageView code="654srth" data={this.state.ImageView}/> 

<div>
<TextView vz="v" type="input"
 code="e3455yf" 
 data={this.state.TextView}
/> 
</div>

                               
<ImageView code="zq351hd" data={this.state.ImageView}/>                       

<div style={{marginTop:15}}>
  <strong>
  SITUAÇÃO ATUAL:
  </strong>
</div>

         </div>
   
  
           <div>{b.site}</div>
         </div>
         
</section>


<section pagina="16.2" id="p2" className="ac4 pg5" style={{display:'block'}}>

         <div class="ctn">
         <div class="area-mtp">
        
     <div class="area-a">
     <div>Data: {this.formatDate(b.data)}</div>
     <div>Rev: V1.2</div>
     <div>Número: AR 01</div>
     <div>Página 21 de 59</div>
     </div>
     
     <div class="area-b">
     <div>A.R. APRECIAÇÃO DE RISCOS</div>
     <div>{b.maquina.toUpperCase()}</div>
     </div>
     
     <div class="area-c">
     c
     </div>
     
     
         </div>
     
         <div className='dados'>
         <RiskAppreciationTable 
                            code="564dzx"
                            data={this.state.FormHrn}
                            resp={(value)=>{
                          
                            }}
                            title="APRECIAÇÃO DE RISCO ANTES DAS AÇÕES DE MELHORIAS"/>
    


<div style={{border:'solid 1px',marginTop:10}}>
  <div style={{textAlign:'center'}}>
    <strong>
    AÇÕES PROPOSTAS COMO SUGESTÃO DE ADEQUAÇÃO
    </strong>
  </div>
  <div style={{margin:5}}>
  <TextView vz="v" type="text"
 code="v3bh2m54" 
 data={this.state.TextView}
/>  


<ImageView code="654sdfv" data={this.state.ImageView}/>    


<TextView vz="v" type="text"
 code="354h6fg5" 
 data={this.state.TextView}
/>  

                          
<ImageView code="654rtdsr6y5" data={this.state.ImageView}/>                         

<TextView vz="v" type="text"
 code="324hgf7" 
 data={this.state.TextView}
/>  


<TextView vz="v" type="text"
 code="s3d654ghsutr" 
 data={this.state.TextView}
/>  
  </div>
</div>



         </div>
   
  
           <div>{b.site}</div>
         </div>
         
</section>


<section pagina="17.2" id="p2" className="ac4 pg5" style={{display:'block'}}>

         <div class="ctn">
         <div class="area-mtp">
        
     <div class="area-a">
     <div>Data: {this.formatDate(b.data)}</div>
     <div>Rev: V1.2</div>
     <div>Número: AR 01</div>
     <div>Página 21 de 59</div>
     </div>
     
     <div class="area-b">
     <div>A.R. APRECIAÇÃO DE RISCOS</div>
     <div>{b.maquina.toUpperCase()}</div>
     </div>
     
     <div class="area-c">
     c
     </div>
     
     
         </div>
     
         <div className='dados'>
         <ImageView code="321t65grt" data={this.state.ImageView}/>



         <TextView vz="v" type="text"
            code="3465465uy" 
            data={this.state.TextView}
            />

<TextView vz="v" type="text"
            code="3a5rg35t" 
            data={this.state.TextView}
            />

<TextView vz="v" type="text"
            code="u9y54sdf" 
            data={this.state.TextView}
            />


   

<div style={{display:'flex'}}>
<ImageView code="3546dfhf" data={this.state.ImageView}/>
<ImageView code="649685df" data={this.state.ImageView}/>
</div>

<TextView vz="v" type="text"
   code="6546845y" 
   data={this.state.TextView}
/>

<ImageView  code="2a654gf" data={this.state.ImageView}/>


<TextView vz="v" type="input"
   code="35df4h65" 
   data={this.state.TextView}
   />

         </div>
   
  
           <div>{b.site}</div>
         </div>
         
</section>


<section pagina="18" id="p2" className="ac4 pg5" style={{display:'block'}}>

         <div class="ctn">
         <div class="area-mtp">
        
     <div class="area-a">
     <div>Data: {this.formatDate(b.data)}</div>
     <div>Rev: V1.2</div>
     <div>Número: AR 01</div>
     <div>Página 21 de 59</div>
     </div>
     
     <div class="area-b">
     <div>A.R. APRECIAÇÃO DE RISCOS</div>
     <div>{b.maquina.toUpperCase()}</div>
     </div>
     
     <div class="area-c">
     c
     </div>
     
     
         </div>
     
         <div className='dados'>
        
      <ImageView code="2164hdfg" data={this.state.ImageView}/>



<TextView vz="v" type="text"
   code="df65g465" 
   data={this.state.TextView}
   />

<ImageView code="324tyhxdf" data={this.state.ImageView}/>



<TextView vz="v" type="text"
   code="re354ty" 
   data={this.state.TextView}
   />


<ImageView code="324retfd" data={this.state.ImageView}/>



<TextView vz="v" type="text"
code="e52ydfsyg" 
data={this.state.TextView}
/>







<ImageView code="654fthjfg" data={this.state.ImageView}/>


         </div>
   
  
           <div>{b.site}</div>
         </div>
         
</section>

<section pagina="19.2" id="p2" className="ac4 pg5" style={{display:'block'}}>

         <div class="ctn">
         <div class="area-mtp">
        
     <div class="area-a">
     <div>Data: {this.formatDate(b.data)}</div>
     <div>Rev: V1.2</div>
     <div>Número: AR 01</div>
     <div>Página 21 de 59</div>
     </div>
     
     <div class="area-b">
     <div>A.R. APRECIAÇÃO DE RISCOS</div>
     <div>{b.maquina.toUpperCase()}</div>
     </div>
     
     <div class="area-c">
     c
     </div>
     
     
         </div>
     
         <div className='dados'>

    <p>
    <TextView vz="v" type="input"
            code="324tydu6" 
            data={this.state.TextView}
      />
    </p>

<p>
<TextView vz="v" type="input"
            code="324ruy5ds" 
            data={this.state.TextView}
      />
</p>

<p>
<TextView vz="v" type="text"
            code="3254dfuf" 
            data={this.state.TextView}
      />
</p>

<p>
<TextView  vz="v" type="input"
            code="3254sdr65" 
            data={this.state.TextView}
      />
</p>


<p>
<TextView vz="v" type="input"
            code="21rd65yt" 
            data={this.state.TextView}
      />
</p>



      <ImageView code="324fghfsdt" data={this.state.ImageView}/>

      <TextView vz="v" type="input"
            code="32srtysf" 
            data={this.state.TextView}
      />
<ImageView code="6354rdshdt" data={this.state.ImageView}/>

<TextView vz="v" type="input"
            code="32dgdfgt" 
            data={this.state.TextView}
            />


         </div>
   
  
           <div>{b.site}</div>
         </div>
         
</section>



<section pagina="20.2" id="p2" className="ac4 pg5" style={{display:'block'}}>

         <div class="ctn">
         <div class="area-mtp">
        
     <div class="area-a">
     <div>Data: {this.formatDate(b.data)}</div>
     <div>Rev: V1.2</div>
     <div>Número: AR 01</div>
     <div>Página 21 de 59</div>
     </div>
     
     <div class="area-b">
     <div>A.R. APRECIAÇÃO DE RISCOS</div>
     <div>{b.maquina.toUpperCase()}</div>
     </div>
     
     <div class="area-c">
     c
     </div>
     
     
         </div>
     
         <div className='dados'>
         <ImageView code="32135tdr" data={this.state.ImageView}/>

<p style={{marginTop:10}}>
<TextView vz="v" type="text"
            code="3254rdahn" 
            data={this.state.TextView}
      />

</p>

<p>
<TextView vz="v" type="input"
            code="354sdrg56" 
            data={this.state.TextView}
      />
</p>

         </div>
   
  
           <div>{b.site}</div>
         </div>
         
</section>



<section pagina="21" id="p2" className="ac4 pg5" style={{display:'block'}}>

         <div class="ctn">
         <div class="area-mtp">
        
     <div class="area-a">
     <div>Data: {this.formatDate(b.data)}</div>
     <div>Rev: V1.2</div>
     <div>Número: AR 01</div>
     <div>Página 21 de 59</div>
     </div>
     
     <div class="area-b">
     <div>A.R. APRECIAÇÃO DE RISCOS</div>
     <div>{b.maquina.toUpperCase()}</div>
     </div>
     
     <div class="area-c">
     c
     </div>
     
     
         </div>
     
         <div className='dados'>

         <RiskAppreciationTable
  code="66zdr48y"
  data={this.state.FormHrn}
  title="APRECIAÇÃO DE RISCO DEPOIS DAS AÇÕES DE MELHORIAS"/>

<div style={{textAlign:'center',marginTop:15}}>
  <strong>
  12.5 Dispositivo de parada de emergência – Geral
  </strong>
</div>

<TextView vz="v" type="input"
            code="2f4gh3er" 
            data={this.state.TextView}
      />

<RiskAppreciationTable
  code="654srgr"
  data={this.state.FormHrn}
  resp={(value)=>{
   
  }}
  title="APRECIAÇÃO DE RISCO ANTES DAS AÇÕES DE MELHORIAS"/>


<div style={{border:'solid 1px',marginTop:15}}>
  <div style={{textAlign:'center'}}>
 <strong>
 AÇÕES PROPOSTAS COMO SUGESTÃO DE ADEQUAÇÃO
 </strong>
  </div>
  <div style={{padding:5}}>

<p>
<TextView vz="v" type="textarea"
            code="324df657ty" 
            data={this.state.TextView}
/>
</p>


<p>
<TextView vz="v" type="textarea"
            code="65t4yhey" 
            data={this.state.TextView}
/>
</p>


<p>
<TextView vz="v" type="input"
            code="054drt2s4" 
            data={this.state.TextView}
/>
</p>

<div style={{display:'table',margin:'auto'}}>
<ImageView code="3254r2e" data={this.state.ImageView}/>

</div>

  </div>
</div>


<RiskAppreciationTable
  code="365rterd"
  data={this.state.FormHrn}
  title="APRECIAÇÃO DE RISCO DEPOIS DAS AÇÕES DE MELHORIAS"/>


<div style={{textAlign:'center'}}>
  <strong>
  12.6 Manutenção, inspeção, preparação, ajuste, reparo e limpeza.
  </strong>
</div>

<div>
  <p>
  12.11.3 A manutenção, inspeção, reparos, limpeza, ajuste e outras intervenções que se fizerem necessárias devem ser executadas por profissionais capacitados, qualificados ou legalmente habilitados, formalmente autorizados pelo empregador, com as máquinas e equipamentos parados e adoção dos seguintes procedimentos:
  </p>
</div>

<div>
  <p>
  a) Isolamento e descarga de todas as fontes de energia das máquinas e equipamentos, de modo visível ou facilmente identificável por meio dos dispositivos de comando;
  </p>
</div>

         </div>
   
  
           <div>{b.site}</div>
         </div>
         
</section>



<section pagina="22" id="p2" className="ac4 pg5" style={{display:'block'}}>

         <div class="ctn">
         <div class="area-mtp">
        
     <div class="area-a">
     <div>Data: {this.formatDate(b.data)}</div>
     <div>Rev: V1.2</div>
     <div>Número: AR 01</div>
     <div>Página 21 de 59</div>
     </div>
     
     <div class="area-b">
     <div>A.R. APRECIAÇÃO DE RISCOS</div>
     <div>{b.maquina.toUpperCase()}</div>
     </div>
     
     <div class="area-c">
     c
     </div>
     
     
         </div>
     
         <div className='dados'>


<p>
  b)  Bloqueio mecânico e elétrico na posição “desligado” ou “fechado” de todos os dispositivos de corte de fontes de energia, a fim de impedir a energização, e sinalização com cartão ou etiqueta de bloqueio contendo o horário e a data do bloqueio, o motivo da manutenção e o nome do responsável;
</p>

<p>
  c) Medidas que garantam que à jusante dos pontos de corte de energia não exista possibilidade de gerar risco de acidentes;
</p>

<p>
d) Medidas adicionais de segurança, quando for realizada manutenção, inspeção e reparos de máquinas ou equipamentos sustentados somente por sistemas hidráulicos e pneumáticos;
</p>

<p>
e) Sistemas de retenção com trava mecânica, para evitar o movimento de retorno.
</p>

<p>
<TextView vz="v" type="input"
            code="3s3rg54" 
            data={this.state.TextView}
      />
</p>


<div style={{display:'table',margin:'auto'}}>
<ImageView code="6546hfr" data={this.state.ImageView}/>
</div>

<RiskAppreciationTable
  code="98rth1"
  data={this.state.FormHrn}
  resp={(value)=>{
  
  }}
  title="APRECIAÇÃO DE RISCO ANTES DAS AÇÕES DE MELHORIAS"/>

         </div>
   
  
           <div>{b.site}</div>
         </div>
         
</section>







<section pagina="23" id="p2" className="ac4 pg5" style={{display:'block'}}>
         <div class="ctn">
         <div class="area-mtp">
        
     <div class="area-a">
     <div>Data: {this.formatDate(b.data)}</div>
     <div>Rev: V1.2</div>
     <div>Número: AR 01</div>
     <div>Página 21 de 59</div>
     </div>
     
     <div class="area-b">
     <div>A.R. APRECIAÇÃO DE RISCOS</div>
     <div>{b.maquina.toUpperCase()}</div>
     </div>
     
     <div class="area-c">
     c
     </div>
     
     
         </div>
     

       

<div className='dados'>

<div style={{border:"solid 1px"}}>
  <div style={{textAlign:'center'}}>
<strong>  AÇÕES PROPOSTAS COMO SUGESTÃO DE ADEQUAÇÃO</strong>
  </div>
  <TextView vz="v" type="text"
            code="fg564gh5" 
            data={this.state.TextView}
      />


    <div style={{display:'table',margin:'auto',marginBottom:10}}>
    <ImageView code="354sery1" data={this.state.ImageView}/>
    </div>



</div>

<RiskAppreciationTable
  code="324rd321"
  data={this.state.FormHrn}
  title="APRECIAÇÃO DE RISCO DEPOIS DAS AÇÕES DE MELHORIAS"/>


<div style={{marginTop:10}}>
  <strong>
  12.7 Sinalização, manuais, procedimentos e capacitação
  </strong>
</div>

<TextView vz="v" type="text"
            code="naer1654" 
            data={this.state.TextView}
      />

<RiskAppreciationTable
  code="24136rt"
  data={this.state.FormHrn}
  title="APRECIAÇÃO DE RISCO ANTES DAS AÇÕES DE MELHORIAS"/>





</div>
        





   



           <div>{b.site}</div>
         </div>
         
</section>



<section pagina="24" id="p2" className="ac4 pg5" style={{display:'block'}}>
         <div class="ctn">
         <div class="area-mtp">
        
     <div class="area-a">
     <div>Data: {this.formatDate(b.data)}</div>
     <div>Rev: V1.2</div>
     <div>Número: AR 01</div>
     <div>Página 21 de 59</div>
     </div>
     
     <div class="area-b">
     <div>A.R. APRECIAÇÃO DE RISCOS</div>
     <div>{b.maquina.toUpperCase()}</div>
     </div>
     
     <div class="area-c">
     c
     </div>
     
     
         </div>
     

       

<div className='dados'>

<div style={{border:"solid 1px"}}>
  <div style={{textAlign:'center'}}>
<strong>  AÇÕES PROPOSTAS COMO SUGESTÃO DE ADEQUAÇÃO</strong>
  </div>
 
  <TextView vz="v" type="text"
            code="3241654sa" 
            data={this.state.TextView}
      />

<div style={{display:'flex'}}>
<ImageView  code="324fthth" data={this.state.ImageView}/>
</div>



 </div>

 <RiskAppreciationTable
  code="02654r4r"
  data={this.state.FormHrn}
  title="APRECIAÇÃO DE RISCO DEPOIS DAS AÇÕES DE MELHORIAS"/>

</div>
        





   



           <div>{b.site}</div>
         </div>
         
</section>


<section id="p2" className="ac4 pg5" style={{display:'block'}}>
         <div class="ctn">
         <div class="area-mtp">
        
     <div class="area-a">
     <div>Data: {this.formatDate(b.data)}</div>
     <div>Rev: V1.2</div>
     <div>Número: AR 01</div>
     <div>Página 21 de 59</div>
     </div>
     
     <div class="area-b">
     <div>A.R. APRECIAÇÃO DE RISCOS</div>
     <div>{b.maquina.toUpperCase()}</div>
     </div>
     
     <div class="area-c">
     c
     </div>
     
     
         </div>
     

       

<div className='dados'>

<div style={{marginLeft:20}}>
  <strong>
 13 -  VISÃO GERAL DE CONFORMIDADE
  </strong>
</div>
<div>
  <strong>Antes das melhorias</strong>
</div>




</div>
        





   



           <div>{b.site}</div>
         </div>
         
</section>


<section id="p2" className="ac4 pg5" style={{display:'block'}}>
         <div class="ctn">
         <div class="area-mtp">
        
     <div class="area-a">
     <div>Data: {this.formatDate(b.data)}</div>
     <div>Rev: V1.2</div>
     <div>Número: AR 01</div>
     <div>Página 21 de 59</div>
     </div>
     
     <div class="area-b">
     <div>A.R. APRECIAÇÃO DE RISCOS</div>
     <div>{b.maquina.toUpperCase()}</div>
     </div>
     
     <div class="area-c">
     c
     </div>
     
     
         </div>
     

       

<div className='dados'>


<img src={h3}/>
<img src={h4}/>


</div>
        





   



           <div>{b.site}</div>
         </div>
         
</section>



<section id="p2" className="ac4 pg5" style={{display:'block'}}>
         <div class="ctn">
         <div class="area-mtp">
        
     <div class="area-a">
     <div>Data: {this.formatDate(b.data)}</div>
     <div>Rev: V1.2</div>
     <div>Número: AR 01</div>
     <div>Página 21 de 59</div>
     </div>
     
     <div class="area-b">
     <div>A.R. APRECIAÇÃO DE RISCOS</div>
     <div>{b.maquina.toUpperCase()}</div>
     </div>
     
     <div class="area-c">
     c
     </div>
     
     
         </div>
     

       

<div className='dados'>

<div>
  <strong>
 14 - CONSIDERAÇÕES FINAIS
  </strong>
</div>

<div>
  <p>
  A máquina denominada CÉLULA DE INJEÇÃO 09 com os sistemas de segurança, arquitetura elétrica e interface de segurança deve atender no mínimo categoria 4 de segurança, levando em consideração as ações propostas como sugestão de melhoria referente a cada item avaliado, segundo NR-12, NBR 12.153: 2015, ISO TR 24119: 2015, ISO 13849-1: 2019 e NBR ISO 12100: 2013.
  </p>
</div>

<div>
  <p>
  Vale ressaltar que, as imagens contidas neste documento são de uso orientativo. A adequação deve ser executada por profissionais habilitados e capacitados para este fim.
  </p>
</div>

<div>
  <p>
  Deve ser elaborado projeto elétrico / mecânico dos sistemas de segurança com base nos itens aplicáveis da NR-12 levando como base a apreciação de riscos. 
  </p>
</div>


<div>
  <p>
  Proteções fixas, móveis e dispositivos opto eletrônicos devem atender as distancias de segurança do ANEXO 1 da NR-12 e EN ISO 13857: 2021. 

  </p>
</div>



</div>
        





   



           <div>{b.site}</div>
         </div>
         
</section>




<section id="p2" className="ac4 pg5" style={{display:'block'}}>
         <div class="ctn">
         <div class="area-mtp">
        
     <div class="area-a">
     <div>Data: {this.formatDate(b.data)}</div>
     <div>Rev: V1.2</div>
     <div>Número: AR 01</div>
     <div>Página 21 de 59</div>
     </div>
     
     <div class="area-b">
     <div>A.R. APRECIAÇÃO DE RISCOS</div>
     <div>{b.maquina.toUpperCase()}</div>
     </div>
     
     <div class="area-c">
     c
     </div>
     
     
         </div>
     

       

<div className='dados'>

<div>
  <strong>
    15 - CORPO TÉCNICO RESPONSÁVEL
  </strong>
</div>

<div>
  <p>Este documento é composto por cinquenta e sete páginas numeradas e assinado na última página pelo corpo técnico responsável pela avaliação e elaboração da “A.R Apreciação de Riscos” </p>
</div>



<div>
  <p>24 de August de 2024</p>
</div>



<hr/>

<div style={{textAlign:'center'}}>
  {this.state.data.tecs.map((item,index)=><>
  <strong>{item.nome}</strong>
  </>)}
</div>

<div style={{textAlign:'center'}}>
  <strong>
  HRN MACHINE SEGURANÇA EM MÁQUINAS
  </strong>
</div>


</div>
        





   



           <div>{b.site}</div>
         </div>
         
</section>




        </div>
       );
     
}

}
  export default Teste;